<template lang="pug">
  div(v-if='compact')
    label(v-if='label' for='inputValue') {{ label }}
    input#inputValue.lightedison__text-field(
      v-on='clisteners'
      :value='modelValue'
      @change="!regular && $emit('change', $event.target.value)"
      @input="regular && $emit('change', $event.target.value)"
      type='text'
      :placeholder='placeholder'
      onwheel='this.blur()'
    )
  v-text-field(
    v-else
    v-on='clisteners'
    :label='label'
    :value='modelValue'
    @change="!regular && $emit('change', $event)"
    @input="regular && $emit('change', $event)"
    dense
    :error-messages='errors'
    :error-count='errors?.length || 0'
    :placeholder='placeholder'
    v-bind='$attrs'
    onwheel='this.blur()'
  )
    template(v-for='(_, field) in $scopedSlots' v-slot:[field]='slotProps')
      slot(:name='field' v-bind='slotProps')
    slot(v-for='(_, name) in $slots' :name='name' :slot='name')
    template(v-slot:label)
      slot(name='label')
        | {{ label }}
        label.red--text(v-if='required') *
</template>
<script lang="ts">
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    compact: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    regular: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    clisteners: function () {
      const { change, input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>
<style scoped lang="scss">
.lightedison__text-field {
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #4a4a4a;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s ease-in-out;
  &:focus {
    outline: none;
    border: 1px solid #409cff;
  }
}
</style>
