import { InstallerRole, JVPartnerRole, LightingReviewerRole, PATHInstallerRole, SuperAdminRole } from '@/config/constants';
import { UserService } from '@/services/auth/userService';
import { getRecord } from '@/services/firebase/FirestoreService';
import { ServiceContainer } from '@/services/serviceContainer';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { TelemetryService } from '@/services/firebase/telemetryService';

const router = new VueRouter({
  routes: routes,
  linkActiveClass: 'partial-active',
  linkExactActiveClass: 'active',
});

const startTime = 0;
const lastNavTime = 0;

// Initial telemetry collection and logging
router.beforeEach((to, from, next) => {
  const telemetryService = ServiceContainer.get(TelemetryService);
  telemetryService.logPageNav(to.fullPath, from.fullPath, lastNavTime);
  next();
});

router.beforeEach((to, _from, next) => {
  const userService = ServiceContainer.get(UserService);

  const requiredAuth = !to.matched.some((record) => record.meta.notRequiredAuth);
  const loggedInUser = userService.getCurrentUser();

  if (requiredAuth && loggedInUser === null) {
    next('/');
  } else if (!requiredAuth && loggedInUser !== null) {
    next('/home');
  } else {
    next();
  }
});

router.beforeEach(async (to, _from, next) => {
  const userService = ServiceContainer.get(UserService);

  const requiredAuth = !to.matched.some((record) => record.meta.notRequiredAuth);
  const loggedInUser = userService.getCurrentUser();

  if (requiredAuth && loggedInUser !== null && to.name !== 'PermissionDenied') {
    const roleId = await userService.getRoleId();
    const role = await userService.getUserRole();

    const roleHasAccess = (role = { routeAccess: [] }, path) => role.routeAccess.includes(path);
    if (roleId === SuperAdminRole || to.matched.every(({ path }) => roleHasAccess(role, path))) {
      next();
    } else {
      next('/permissionDenied');
    }

    // A better solution is needed here, maybe a more generic one
    let opptyIDs = [];
    if ([InstallerRole, PATHInstallerRole, LightingReviewerRole, JVPartnerRole].includes(roleId)) {
      const intallerOpportunities = await getRecord({
        collectionName: 'installerOpportunities',
        id: loggedInUser.uid,
      });
      opptyIDs = intallerOpportunities.opptyIDs || [];
    }

    //
    if ([InstallerRole, PATHInstallerRole].includes(roleId)) {
      if (
        ['home', 'PM Verification'].includes(to.name) ||
        (to.name === 'InstallationMap' && opptyIDs.indexOf(to.params.opportunityId) > -1) ||
        (to.meta && to.meta.allowInstaller)
      ) {
        next();
      } else {
        next('/home');
      }
    } else if (roleId === JVPartnerRole) {
      if (
        ['home'].includes(to.name) ||
        (to.name === 'InstallationMap' && opptyIDs.indexOf(to.params.opportunityId) > -1) ||
        (to.meta && to.meta.allowInstaller)
      ) {
        next();
      } else {
        next('/home');
      }
    } else if (roleId === LightingReviewerRole) {
      if (
        ['home', 'MissingData', 'QuickCode', 'QuickCodeArchived'].includes(to.name) ||
        (to.name === 'Spaces' && opptyIDs.indexOf(to.params.id) > -1)
      ) {
        next();
      } else {
        next('/home');
      }
    } else {
      if (to.meta && to.meta.accessControl) {
        if (to.meta.accessControl()) {
          next();
        } else {
          next('/home');
        }
      }
    }
  } else next();
});

router.afterEach(() => {
  const endTime = Date.now();
  const duration = endTime - startTime;
  console.debug(`Route navigation took ${duration}ms`);
});

export default router;
