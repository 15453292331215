import firebase from 'firebase';
import { UserService } from '../auth/userService';
import { ServiceContainer } from '../serviceContainer';
import 'firebase/analytics';

export class TelemetryService {
  private readonly userService: UserService;

  constructor() {
    this.userService = ServiceContainer.get(UserService);
  }

  public logPageNav(to: string, from: string, lastNavTime: number) {
    const loggedInUser = this.userService.getCurrentUser();
    const telemetryData = {
      userName: loggedInUser ? loggedInUser.displayName : 'Guest',
      userID: loggedInUser ? loggedInUser.uid : null,
      userAgent: navigator.userAgent,
      from: from,
      to: to,
      date: new Date().toISOString(),
      timeElapsed: Date.now() - lastNavTime,
    };
    try {
      firebase.analytics().logEvent('page_navigation', telemetryData);
      console.debug(telemetryData);
    } catch (error) {
      console.error('Failed to log telemetry event:', error);
    }
  }
}
