import { Globals } from '@/config/constants';
import { deleteImages, pictureUpload } from '@/services/firebase/StorageService.js';
import { fieldReducer, firestoreDocument, mapperReducer, querySnapshotToArray, withQuery } from '@/services/utils/Utils.js';
import firebase from 'firebase';
import get from 'lodash/get.js';

// Axios for calling users records.
// Will be put on firestore so getRecords functions is also able to populate by users, which is not a firestore collection.
import axios from 'axios';

const getUser = async (id) => {
  const auth = firebase.auth();
  const url = Globals.fireBaseUrl + 'getUserByUID';
  return auth.currentUser
    .getIdToken(/* forceRefresh */ true)
    .then(() => axios.post(url, { uid: id }))
    .then(({ data }) => ({ ...data, id: data.uid }))
    .catch((error) => reject(error));
};

const getUsers = async () => {
  const auth = firebase.auth();
  const url = Globals.fireBaseUrl + 'listAllUsers';
  return auth.currentUser
    .getIdToken(/* forceRefresh */ true)
    .then((idToken) => axios.post(url, { token: idToken }))
    .then(({ data }) => data);
};

/**
 * It gets the metadata, updates the document with the incremented metadata number
 * and then updates the metadata document with the incremented number
 * @param  {firebase.firestore.DocumentReference} metadataDocument The metadata document
 * @param  {firebase.firestore.DocumentReference} recordDocument The firebase document instance
 * @param  {Object} data The document data
 * @param  {string} metadataField The metadata field to update
 * @return {promise} A promise that resolves nothing but the notification that the transaction has been made
 */
const saveWithMetadata = (metadataDocument, recordDocument, data, metadataField = 'count') => {
  const firestore = firebase.firestore();
  return firestore.runTransaction(async function (transaction) {
    return transaction.get(metadataDocument).then((metadataDoc) => {
      const newCount = metadataDoc.exists ? (metadataDoc.get(metadataField) ?? 0) + 1 : 1;
      transaction.set(recordDocument, {
        ...data,
        id: recordDocument.id,
        recordNumber: newCount,
      });
      transaction.set(metadataDocument, { [metadataField]: newCount }, { merge: true });
      data.id = recordDocument.id;
      data.recordNumber = newCount;
    });
  });
};

/**
 * Saves a given firestore record (WITHOUT IMAGES)
 * @param  {string} id The firebase document instance (null or undefined would mean that the document is new)
 * @param  {object} data the document data
 * @param  {string} collectionName the firestore collection name of the record to save
 * @param  {string} metadataDocumentID The metadata document ID.
 * @param  {boolean} forceNew Used to force the creation of a new record with both recordNumber and id
 * @param  {string} metadataField The metadata field to update. Useful if you want local counting per group like opportunity
 * @return {Promise} A promise that resolves the saved record's ID
 */
const saveRecord = ({ id, data, collectionName, metadataDocumentID, forceNew = false, metadataField = 'count' }) => {
  const recordDocument = firestoreDocument(collectionName)(id);
  const metadataDocument = metadataDocumentID && firestoreDocument('global')(metadataDocumentID);

  let promise;

  if (metadataDocument && (!id || forceNew)) {
    // Running a transaction in order to read the metadata document
    // And making a new document with the appropiate recordNumber property
    promise = saveWithMetadata(metadataDocument, recordDocument, data, metadataField);
  } else {
    promise = !id ? recordDocument.set({ ...data }) : recordDocument.update({ ...data });
  }

  return promise.then(() => recordDocument.id);
};

/**
 * Deletes a given firestore record
 * @param  {string} id The firebase document instance (null or undefined would mean that the document is new)
 * @return {promise} A promise that resolves nothing
 */
const deleteRecord = async ({ id, collectionName, pictures = [] }) => {
  const recordDocument = firestoreDocument(collectionName)(id);
  return pictures.length ? deleteImages(pictures).then(() => recordDocument.delete()) : recordDocument.delete();
};

/**
 * Saves a given firestore record (WITH IMAGES)
 * @param  {string} id The firebase document instance (null or undefined would mean that the document is new)
 * @param  {object} data the document data
 * @param  {array} pictures the pictures to save
 * @param  {string} parentFolder the firebase storage file path, see examples on the modules using firebase storage or this same function in the future
 * @param  {string} collectionName the firestore collection name of the record to save
 * @param  {string} metadataDocumentID The metadata document ID.
 * @return {promise} A promise that resolves the saved record's ID
 */
const saveRecordWithPictures = ({ id, data, pictures = [], parentFolder, collectionName, metadataDocumentID }) =>
  new Promise((resolve, reject) => {
    pictureUpload(pictures, parentFolder, deleteImages)
      .then((pictureUrls) => {
        const recordDocument = firestoreDocument(collectionName)(id);
        const metadataDocument = metadataDocumentID && firestoreDocument('global')(metadataDocumentID);

        let promise;
        const formObject = {
          ...data,
          pictures: [...(data.pictures || []), ...pictureUrls],
        };

        if (metadataDocument && !id) {
          promise = saveWithMetadata(metadataDocument, recordDocument, formObject);
        } else {
          promise = !id ? recordDocument.set(formObject) : recordDocument.update(formObject);
        }

        return Promise.all([promise, id || recordDocument.id]);
      })
      .then(([saveResponse, id]) => {
        resolve(id);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Splits an array into several arrays with the length of "size"
// Input: an array for the reducer, the max length for every splitted array
// The final result is an array of arrays
// It must be used with [[]] as the initial value for the accumulator
const arraySplitReducer = (size) => (acc, item) => {
  if (acc[acc.length - 1].length < size) {
    acc[acc.length - 1].push(item);
    return acc;
  } else {
    return [...acc, [item]];
  }
};

const getFromQuery = (statements = []) => {
  return async function (collection) {
    // Extended in queries are those where statements
    // with ("field", "in", [...items]) where the "items"
    // array has more than 10 items

    const extendedQueryCondition = (item) => item[1] == 'in' && Array.isArray(item[2]) && item[2].length > 10;

    const extendedInQueries = statements.filter(extendedQueryCondition);
    const regularQueries = statements.filter((item) => !extendedQueryCondition(item));

    const regularQueryRecords = querySnapshotToArray(await withQuery(regularQueries)(collection).get());

    let extendedRecords = [];

    if (extendedInQueries.length) {
      const extendedQueryRecordsReducer = async (acc, [field, operator, criteria]) => {
        return [
          ...(await acc),
          ...(
            await Promise.all(
              criteria.reduce(arraySplitReducer(10), [[]]).map((criteria) => collection.where(field, operator, criteria).get()),
            )
          ).reduce((acc, querySnapshot) => [...acc, ...querySnapshotToArray(querySnapshot)], []),
        ];
      };

      extendedRecords = await extendedInQueries.reduce(extendedQueryRecordsReducer, []);
    }

    const operators = {
      '<': (field, value) => (item) => get(item, field) < value,
      '<=': (field, value) => (item) => get(item, field) <= value,
      '==': (field, value) => (item) => get(item, field) == value,
      '>': (field, value) => (item) => get(item, field) > value,
      '>=': (field, value) => (item) => get(item, field) >= value,
      '!=': (field, value) => (item) => get(item, field) != value,
      'array-contains': (field, value) => (item) => Array.isArray(get(item, field)) && get(item, field).includes(value),
      'array-contains-any':
        (field, values = []) =>
        (item) =>
          Array.isArray(get(item, field)) && values.some((value) => get(item, field).includes(value)),
      in:
        (field, values = []) =>
        (item) =>
          values.includes(get(item, field)),
      'not-in':
        (field, values = []) =>
        (item) =>
          !values.includes(get(item, field)),
    };

    const validateCriteria = (operatorsConfiguration) => (item, criteria) =>
      criteria.reduce((acc, [field, operator, value]) => {
        if (!acc) return false;
        return operatorsConfiguration[operator](field, value)(item);
      }, true);

    return [...regularQueryRecords, ...extendedRecords].reduce((acc, item) => {
      return [...acc, ...(validateCriteria(operators)(item, statements) && !acc.find((record) => record.id == item.id) ? [item] : [])];
    }, []);
  };
};

const getRecordsByIdArray = async (collection, ids = []) => {
  // We split the arrays into smaller arrays of size 10 because that's
  // the limit allowed by the "in" operator on the firestore "where" query
  // https://stackoverflow.com/questions/62009800/google-cloud-firestore-in-operator-workaround
  const splittedIds = ids.reduce(arraySplitReducer(10), [[]]);
  const queryByIdsArray = (idsArray) => {
    return collection.where(firebase.firestore.FieldPath.documentId(), 'in', idsArray).get();
  };

  const querySnapshotsMergeReducer = (acc, querySnapshot) => [...acc, ...querySnapshotToArray(querySnapshot)];

  // With this request we will get an array of firestore query snapshots
  const querySnapshots = await Promise.all(splittedIds.filter((item) => item.length).map(queryByIdsArray));

  return querySnapshots.reduce(querySnapshotsMergeReducer, []);
};

async function getRecord({ collectionName, id, populations = {} }) {
  const record = (await getRecords({ collectionName, idsArray: [id], populations }))[0];
  if (record) return record;
  else throw new Error('This record does not exist.');
}

async function getRecords({ collectionName, idsArray = [], whereStatements = [], populations = {} }) {
  const firestore = firebase.firestore();
  const collection = firestore.collection(collectionName);

  try {
    let records = [];

    if (collectionName === 'users') {
      //
      let users = [];
      const getUserFields = ({ displayName, email, uid: id, role, phoneNumber }) => ({
        displayName,
        email,
        id,
        role,
        phoneNumber,
      });

      //
      if (idsArray && Array.isArray(idsArray)) {
        users = (await Promise.allSettled(idsArray.map((id) => getUser(id)))).map((response, index) =>
          response.status === 'rejected' ? { id: idsArray[index] } : { ...response.value, id: response.value.uid },
        );
      } else {
        users = await getUsers();
      }

      records = users.map((user) => getUserFields(user));
    } else {
      if (whereStatements.length) {
        records = await getFromQuery(whereStatements)(collection);
      } else if (Array.isArray(idsArray) && idsArray.length) {
        records = await getRecordsByIdArray(collection, idsArray);
      } else {
        records = querySnapshotToArray(await collection.get());
      }
    }

    if (populations && Object.keys(populations).length) {
      for (const key in populations) {
        const populationIdsArray = records.reduce(fieldReducer(key), []);

        //
        const population = populations[key];
        const populationRecords = await getRecords({
          collectionName: population['collectionName'],
          fields: population['fields'],
          idsArray: populationIdsArray,
          populations: population['populations'],
        });

        const populationRecordsToMap = populationRecords.reduce(mapperReducer, {});
        records = records.map((record) => ({
          ...record,
          [key]: populationRecordsToMap[record[key]],
        }));
      }
    }

    return records;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}

export { deleteRecord, getRecord, getRecords, saveRecord, saveRecordWithPictures, saveWithMetadata };
