import { querySnapshotToArray, firestoreDocument } from '@/services/utils/Utils.js';
import { saveRecordWithPictures } from '@/services/firebase/FirestoreService.js';
import firebase from 'firebase';
import { companyName } from '@/config/constants';

export const namespaced = true;

export const state = {
  requests: [],
  statuses: [],
  appTypes: [],
  priorities: [],
  severity: [],
  effort: [],
  ciAffectedLevels: [],
  requestTypes: [],
  subscription: null,
};

export const getters = {
  getRequestById: (state) => (id) => {
    return state.requests.find((request) => request.id === id);
  },
  requests: ({ requests }) => requests,
  statuses: ({ statuses }) => statuses,
  appTypes: ({ appTypes }) => appTypes,
  requestTypes: ({ requestTypes }) => requestTypes,
  priorities: ({ priorities }) => priorities,
  severity: ({ severity }) => severity,
  effort: ({ effort }) => effort,
  ciAffectedLevels: ({ effort }) => effort,
  statusesForPicklist: (state) => {
    return state.statuses.map((status) => Object.values(status).pop());
  },
  appTypesForPicklist: (state) => {
    return state.appTypes.map((status) => ({
      label: Object.keys(status).pop(),
      value: Object.values(status).pop(),
    }));
  },
  requestTypesForPicklist: (state) => {
    return state.requestTypes.map((status) => ({
      label: Object.keys(status).pop(),
      value: Object.values(status).pop(),
    }));
  },
  prioritiesForPicklist: (state) => {
    return state.priorities.map((status) => Object.values(status).pop());
  },
  severityForPicklist: (state) => {
    return state.severity.map((status) => Object.values(status).pop());
  },
  effortForPicklist: (state) => {
    return state.effort.map((status) => Object.values(status).pop());
  },
  ciAffectedLevelsForPicklist: (state) => {
    return state.ciAffectedLevels.map((status) => Object.values(status).pop());
  },
};

export const mutations = {
  SET_REQUESTS(state, requests) {
    state.requests = requests;
  },
  SET_PICKLIST(state, { key, values }) {
    state[key] = values;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
  UNSUBSCRIBE(state) {
    state.subscription = null;
  },
};

export const actions = {
  fetchPicklists({ commit }) {
    const errorFn = (error) => {
      console.log(error);
      swal(
        'Error!',
        `Something went wrong when the CI statuses Picklist, please try again or contact with ${companyName}'s IT Department`,
        'error',
      );
    };
    firestoreDocument('picklistsNew2')('ciStatus')
      .get()
      .then((querySnapshot) => {
        const { elements } = querySnapshot.data();
        // context.state.statuses = elements;
        commit('SET_PICKLIST', { key: 'statuses', values: elements });
      })
      .catch(errorFn);
    firestoreDocument('picklistsNew2')('ciRequestType')
      .get()
      .then((querySnapshot) => {
        const { elements } = querySnapshot.data();
        // context.state.statuses = elements;
        commit('SET_PICKLIST', { key: 'requestTypes', values: elements });
      })
      .catch(errorFn);
    firestoreDocument('picklistsNew2')('ciAppType')
      .get()
      .then((querySnapshot) => {
        const { elements } = querySnapshot.data();
        // context.state.statuses = elements;
        commit('SET_PICKLIST', { key: 'appTypes', values: elements });
      })
      .catch(errorFn);
    firestoreDocument('picklistsNew2')('priority')
      .get()
      .then((querySnapshot) => {
        const { elements } = querySnapshot.data();
        // context.state.statuses = elements;
        commit('SET_PICKLIST', { key: 'priorities', values: elements });
      })
      .catch(errorFn);
    firestoreDocument('picklistsNew2')('severity')
      .get()
      .then((querySnapshot) => {
        const { elements } = querySnapshot.data();
        // context.state.statuses = elements;
        commit('SET_PICKLIST', { key: 'severity', values: elements });
      })
      .catch(errorFn);
    firestoreDocument('picklistsNew2')('ciEffort')
      .get()
      .then((querySnapshot) => {
        const { elements } = querySnapshot.data();
        // context.state.statuses = elements;
        commit('SET_PICKLIST', { key: 'effort', values: elements });
      })
      .catch(errorFn);
    firestoreDocument('picklistsNew2')('ciAffectedLevels')
      .get()
      .then((querySnapshot) => {
        const { elements } = querySnapshot.data();
        // context.state.statuses = elements;
        commit('SET_PICKLIST', { key: 'ciAffectedLevels', values: elements });
      })
      .catch(errorFn);
  },
  fetchRequests({ commit, state }) {
    if (state.subscription) {
      return;
    }

    const collection = firebase.firestore().collection('improvementRequests');
    const subscription = collection.onSnapshot((querySnapshot) => {
      commit('SET_REQUESTS', querySnapshotToArray(querySnapshot));
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  saveRequest(context, requestOptions) {
    return saveRecordWithPictures(requestOptions).then((id) => id);
  },
  destroy({ commit, state }) {
    if (state.subscription) {
      state.subscription();
      commit('UNSUBSCRIBE');
    }
  },
  fetchRequest(context, id) {
    return new Promise((resolve, reject) => {
      const requestsDocument = firestoreDocument('improvementRequests')(id);
      requestsDocument
        .get()
        .then((querySnapshot) => {
          resolve({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
