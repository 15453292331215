import { ServiceContainer } from '@/services/serviceContainer';
import firebase from 'firebase/app';
import { Deferred } from '../../../../core/helpers/deferredPromise';
import { CacheService } from '../../cache/cacheService';
import { IFirestoreService, Unsubscribe } from './iFirestoreService';
import { Snapshot } from './snapshot';
import { SnapshotRepository } from './snapshotRepository';

export class FirestoreService implements IFirestoreService {
  private readonly snapshotRepository: SnapshotRepository;
  private readonly cacheService: CacheService;

  public constructor(
    snapshotRepository: SnapshotRepository = ServiceContainer.get(SnapshotRepository),
    cacheService: CacheService = ServiceContainer.get(CacheService),
  ) {
    this.snapshotRepository = snapshotRepository;
    this.cacheService = cacheService;
  }

  public async getCollection<T>(collectionId: string): Promise<T[]> {
    const deferred = Deferred.promiseOf<T[]>();

    const snapshot = this.getSnapshot<T>(collectionId);

    if (snapshot.data) {
      return deferred.resolve(snapshot.data as T[]);
    }

    this.subscribeToSnapshot(collectionId, deferred);

    this.resolveFromCache(collectionId, (data) => deferred.resolve(data as T[]));

    return deferred.promise;
  }

  public async getDocument<T>(collectionId: string, documentId: string): Promise<T> {
    const deferred = Deferred.promiseOf<T>();
    const requestKey = this.getRequestKey(collectionId, documentId);

    const snapshot = this.getSnapshot<T>(collectionId, documentId);

    if (snapshot.data) {
      return deferred.resolve(snapshot.data as T);
    }

    this.subscribeToSnapshot(requestKey, deferred);

    this.resolveFromCache(requestKey, (data) => deferred.resolve(data as T));

    return deferred.promise;
  }

  private getSnapshot<T>(collection: string, documentId?: string): Snapshot {
    const requestKey = this.getRequestKey(collection, documentId);

    if (!this.snapshotRepository.hasSnapshot(requestKey)) {
      if (documentId) {
        this.createDocumentSnapshot<T>(collection, documentId, requestKey);
      } else {
        this.createCollectionSnapshot<T>(collection, requestKey);
      }
    }

    return this.snapshotRepository.getSnapshot(requestKey);
  }

  private subscribeToSnapshot(requestKey: string, deferred: Deferred<unknown>): void {
    const subscriptionId = this.snapshotRepository.addObserver(requestKey, (data) => {
      deferred.resolve(data);
      this.snapshotRepository.removeObserver(requestKey, subscriptionId);
    });
  }

  private resolveFromCache<T>(requestKey: string, onLoaded: (data: T) => void): void {
    this.cacheService.get<T>(requestKey).then((cachedData) => {
      if (cachedData) {
        onLoaded(cachedData);
      }
    });
  }

  public subscribeToDocument<T>(collectionId: string, documentId: string, onUpdate: (data: T) => void): Unsubscribe {
    const requestKey = this.getRequestKey(collectionId, documentId);

    const snapshot = this.getSnapshot<T>(collectionId, documentId);

    const observerId = this.snapshotRepository.addObserver(requestKey, onUpdate);

    if (snapshot.data) {
      onUpdate(snapshot.data as T);
    } else {
      this.resolveFromCache(requestKey, onUpdate);
    }

    return () => this.snapshotRepository.removeObserver(requestKey, observerId);
  }

  public subscribeToCollection<T>(collectionId: string, onUpdate: (data: T[]) => void): Unsubscribe {
    const requestKey = this.getRequestKey(collectionId);

    const snapshot = this.getSnapshot<T>(requestKey);

    const observerId = this.snapshotRepository.addObserver(requestKey, onUpdate);

    if (snapshot.data) {
      onUpdate(snapshot.data as T[]);
    } else {
      this.resolveFromCache(requestKey, onUpdate);
    }

    return () => this.snapshotRepository.removeObserver(collectionId, observerId);
  }

  public async deleteDocument(collectionId: string, documentId: string): Promise<void> {
    await firebase.firestore().collection(collectionId).doc(documentId).delete();
  }

  private createDocumentSnapshot<T>(collectionId: string, documentId: string, requestKey: string): void {
    const unsubscribe = firebase
      .firestore()
      .collection(collectionId)
      .doc(documentId)
      .onSnapshot({
        next: (docSnapshot) => this.onNextDocumentSnapshot<T>(docSnapshot, requestKey),
        error: (error) => this.onSnapshotError(error, requestKey),
      });
    this.snapshotRepository.registerSnapshot(requestKey, unsubscribe);
  }

  private createCollectionSnapshot<T>(collectionId: string, requestKey: string): void {
    const unsubscribe = firebase
      .firestore()
      .collection(collectionId)
      .onSnapshot({
        next: (querySnapshot) => this.onNextCollectionSnapshot<T>(querySnapshot, requestKey),
        error: (error) => this.onSnapshotError(error, requestKey),
      });
    this.snapshotRepository.registerSnapshot(requestKey, unsubscribe);
  }

  private onNextCollectionSnapshot = <T>(querySnapshot: firebase.firestore.QuerySnapshot, requestKey: string) => {
    const data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as T[];

    this.snapshotRepository.publish(requestKey, data);
    this.cacheService.set(requestKey, data);
  };

  private onNextDocumentSnapshot = <T>(docSnapshot: firebase.firestore.DocumentSnapshot, requestKey: string) => {
    const data = docSnapshot.exists ? ({ ...docSnapshot.data(), id: docSnapshot.id } as T) : null;
    this.snapshotRepository.publish(requestKey, data);
    this.cacheService.set(requestKey, data);
  };

  private onSnapshotError = (error: firebase.FirebaseError, requestKey: string) => {
    if (error.code === 'permission-denied') {
      console.info(`Snapshot ${requestKey} closed due to change in auth state.`);
    } else {
      console.error(`Firestore error for ${requestKey}:`, error);
    }
    this.snapshotRepository.removeSnapshot(requestKey);
  };

  private getRequestKey(collectionId: string, documentId?: string): string {
    return documentId ? `${collectionId}/${documentId}` : collectionId;
  }
}
