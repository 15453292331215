import firebase from 'firebase';

const deleteImages = (images = []) => {
  const storage = firebase.storage();
  const refs = images.map(({ url }) => storage.refFromURL(url));
  return Promise.all(refs.map((ref) => ref.delete()));
};

// It downloads raw files from firebase storage
// url: the image's download URL from firebase storage
const getBlobFromURL = (url) => {
  return new Promise((resolve, reject) => {
    try {
      // This can be downloaded directly:
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function (event) {
        var blob = xhr.response;
        resolve(blob);
      };
      xhr.open('GET', url);
      xhr.send();
    } catch (error) {
      reject(error);
    }
  });
};

// Picture download would be something like a
// higher level function on top of "getBlobFromURL" function.
const pictureDownload = (blobConverter) => (downloadURLs) =>
  new Promise(async (resolve, reject) => {
    const pictures = [];
    try {
      for (const downloadURL of downloadURLs) {
        const date = new Date();
        const newFileName = date.getTime();
        let blob = await blobConverter(downloadURL);

        // heic blob files are being downloaded with a type of 'application/octet-stream'
        // ...hence this fix.
        if (blob.type === 'application/octet-stream') {
          blob = blob.slice(0, blob.size, 'image/heic');
        }

        const extension = `.${blob.type.split('/')[1]}`;
        pictures.push({ fileName: `${newFileName}${extension}`, file: blob });
      }

      resolve(pictures);
    } catch (error) {
      console.log('hooo: ', error);
      if (error.error && error.error.code == 404) {
        reject({ code: error.error.code, message: 'File not found.' });
      }
      reject({
        message: `Something went wrong when downloading the ${downloadURLs.length > 1 ? 'pictures' : 'picture'}.`,
      });
    }
  });

const pictureUpload = async (files, parents, errorHandler = undefined, keepFilename = false, fileRelationsMap = false) =>
  new Promise(async (resolve, reject) => {
    const storage = firebase.storage();
    const uploadedPictures = [];

    const fileNames = [];

    const getFileName = (fileName, previousFileName) => {
      if (Number(fileName) > Number(previousFileName)) {
        return fileName;
      } else {
        return String(Number(previousFileName) + 1);
      }
    };

    return Promise.all(
      files.map((img, index) => {
        const date = new Date();
        const timestamp = date.getTime();
        const newFileName = index === 0 ? timestamp : getFileName(timestamp, fileNames[index - 1]);
        const extension = img.fileName.slice(img.fileName.lastIndexOf('.'));
        const finalFilename = (keepFilename ? img.fileName.slice(0, img.fileName.lastIndexOf('.')) + '_' : '') + newFileName + extension;
        const uploadPath = `${parents}/${finalFilename}`;

        const uploadPointer = storage.ref().child(uploadPath);
        const uploadRequest = uploadPointer.put(img.file);
        fileNames.push(newFileName);
        return uploadRequest
          .then((uploadTaskSnapshot) => uploadTaskSnapshot.ref.getDownloadURL())
          .then((downloadUrl) => {
            if (fileRelationsMap) {
              uploadedPictures.push({
                filename: finalFilename,
                url: downloadUrl,
                mimetype: img.mimetype || 'unknown',
              });
            } else {
              uploadedPictures.push({
                name: finalFilename,
                url: downloadUrl,
                type: img.type || 'unknown',
              });
            }
          });
      }),
    )
      .then(() => {
        resolve(uploadedPictures);
      })
      .catch((error) => {
        console.log(error);

        return (errorHandler ? errorHandler(uploadedPictures) : Promise.resolve()).then(() => {
          reject(error);
        });
      });
  });

/**
 * Uploads new pictures to firebase storage
 * @param files
 * @param folder
 * @returns {Promise<*[]>}
 */
const picturesUploadNew = (files, folder) => {
  const pendingFiles = files.filter((f) => !f.url);
  const existingFiles = files.filter((f) => f.url);
  if (pendingFiles.length !== 0) return pictureUpload(pendingFiles, folder).then((uploadedFiles) => [...existingFiles, ...uploadedFiles]);
  else return Promise.resolve(existingFiles);
};
export { deleteImages, pictureUpload, picturesUploadNew, pictureDownload, getBlobFromURL };
