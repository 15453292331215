<template>
  <v-footer padless color="transparent">
    <v-col class="text-center" cols="12">
      <strong>{{ companyName }}, Inc</strong> &copy;
      {{ new Date().getFullYear() }}
    </v-col>
  </v-footer>
</template>

<script lang="ts">
import { companyName } from '@/config/constants';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AppFooter',
  data() {
    return {
      companyName: '',
    };
  },
  created() {
    this.companyName = companyName;
  },
});
</script>
