export class ServiceContainer {
  private static services: Map<string, unknown> = new Map();

  public static get<T>(singleton: new () => T): T {
    if (!this.services.has(singleton.name)) {
      console.debug(`Creating new instance of ${singleton.name}`);
      this.services.set(singleton.name, new singleton());
    }
    return this.services.get(singleton.name) as T;
  }

  public static resetInstance(): void {
    this.services.clear();
  }

  public static register<T>(serviceKey: string, instance: T): void {
    this.services.set(serviceKey, instance);
  }
}
