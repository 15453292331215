<template>
  <v-card>
    <v-img v-if="type === 'image'" :src="convertedImageUrl" alt="img" class="light-edison__image">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey darken-1"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <div v-else-if="type === 'PDF'" class="d-flex justify-center align center" style="height: 220px">
      <v-icon x-large>mdi-file-pdf-box</v-icon>
    </div>

    <video v-else-if="type === 'video'" class="light-edison__image" :src="convertedImageUrl" controls></video>

    <div v-else-if="type === 'Unknown'" class="d-flex justify-center align center" style="height: 220px">
      <v-icon x-large>mdi-file</v-icon>
    </div>

    <div class="light-edison__overlay" :class="{ video: type === 'video' }">
      <div class="light-edison__img-overlay-top">
        <div class="light-edison__flex-container--left">
          <label class="light-edison__img-title">{{ file.name }}</label>
        </div>
      </div>

      <div class="light-edison__img-overlay-bottom">
        <div class="light-edison__flex-container--right">
          <div class="buttons light-edison__img-button">
            <a v-if="type === 'image'" :href="convertedImageUrl" data-magnify="gallery" data-caption="Image">
              <v-btn color="primary" small>Open</v-btn>
            </a>
            <a target="_blank" v-else :href="convertedImageUrl">
              <v-btn color="primary" small>Open</v-btn>
            </a>

            <v-btn v-if="!readonly && isDeletable" dark @click="$emit('delete')" color="red" small>Delete </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import heic2any from 'heic2any';
import { companyName } from '@/config/constants';

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default() {
        return {
          name: null,
          url: null,
        };
      },
    },
  },
  watch: {
    file({ url }) {
      this.fetchFile(url);
    },
  },
  data() {
    return {
      isDeletable: false,
      convertedImageUrl: null,
      type: null,
    };
  },
  mounted() {
    const listeners = this.$listeners;
    this.isDeletable = listeners.hasOwnProperty('delete');
    this.fetchFile(this.file.url);
  },
  methods: {
    fetchFile(url) {
      if (!url) {
        return;
      }

      this.type = null;

      this.$http
        .get(url, {
          responseType: 'blob',
        })
        .then((blob) => {
          const { type } = blob.body;

          if (['image/heic', 'application/octet-stream'].includes(type)) {
            this.type = 'Image';
            return heic2any({ blob: blob.body });
          } else if (type === 'application/pdf') {
            this.type = 'PDF';
            return blob.body;
          } else if (type.includes('video/')) {
            this.type = 'video';
            return blob.body;
          } else if (type.includes('image/')) {
            this.type = 'image';
            return blob.body;
          } else {
            this.type = 'Unknown';
            return blob.body;
          }
        })
        .then((blobBody) => {
          this.convertedImageUrl = URL.createObjectURL(blobBody);
        })
        .catch((error) => {
          console.log(error);
          swal(
            'Error!',
            `Something went wrong when loading a file, please try again or contact with ${companyName}'s IT Department`,
            'error',
          );
        });
    },
  },
};
</script>

<style>
.light-edison__image {
  width: 100%;
  height: 220px;
}

.light-edison__image-container {
  text-align: center;
  margin: 10px 0;
}

.light-edison__overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(44, 44, 44, 0.5), rgba(0, 0, 0, 0));
}

.light-edison__overlay.video {
  height: 60px;
}

.light-edison__img-overlay-top {
  text-align: left;
}

.light-edison__img-overlay-bottom {
  text-align: right;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.light-edison__flex-container--right {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.light-edison__flex-container--left {
  display: flex;
  justify-content: flex-start;
  padding: 15px;
}

.light-edison__img-title {
  color: white;
  font-size: 15px;
}
</style>
