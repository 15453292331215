<template>
  <div class="light-edison__container light-edison__sop-form">
    <v-dialog v-model="isRecordLoading" overlay persistent width="300">
      <v-card color="terciary" dark>
        <v-card-text>
          Getting Record Information
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ViewBanner :title="title" :subtitle="subtitle" :breadcrumbs-items="embedded ? [] : breadcrumbsItems" />

    <v-expansion-panels multiple accordion v-model="panels">
      <!-- SOPs Information -->
      <v-expansion-panel>
        <v-expansion-panel-header color="terciary">
          <template v-slot:default>
            <v-toolbar color="transparent" flat height="40" dark>
              <p class="subtitle-1 mb-0">SOP/Policy Information</p>
              <v-divider inset vertical class="mx-3"></v-divider>
              <v-menu offset-y v-if="canGenerate">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="stopPropagation($event)" class="mx-1" small outlined>Generate New Version</v-btn>
                </template>

                <v-card class="pa-2">
                  <p class="text-center v-label mb-1">Please pick how would you like to generate the new version.</p>
                  <v-card-actions
                    :class="{
                      'd-flex justify-center': true,
                      'flex-column': !$vuetify.breakpoint.mdAndUp,
                    }"
                  >
                    <v-btn v-if="version" dark color="primary" small @click.prevent="generateNewVersion({ fromCurrent: true })"
                      >Generate From Current Version ({{ formattedVersionNumber }})
                    </v-btn>

                    <v-btn dark color="green" small @click.prevent="generateNewVersion()">New Empty Version</v-btn>
                    <v-btn dark color="red" small>Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <p class="white--text mb-0" v-else-if="existingRequestingApprovalVersion">
                There's a version already requesting for approval
              </p>
            </v-toolbar>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="formContainerClasses" color="white">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <BaseInput
                  v-model="$v.form.name.$model"
                  :outlined="isEdit"
                  regular
                  :readonly="!isEdit"
                  type="text"
                  label="SOP/Policy Name"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                  :errors="isSubmitted && $v.form.name.$invalid ? ['Name is required.'] : []"
                >
                  <template v-slot:append v-if="!isEdit && canUpdate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on" @click="isEdit = true">
                          <v-icon small color="primary">mdi-pencil</v-icon>
                        </a>
                      </template>
                      <span>Edit SOP/Policy Name</span>
                    </v-tooltip>
                  </template>
                </BaseInput>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <BaseAutocomplete
                  :items="types"
                  :readonly="!isEdit"
                  :clearable="isEdit"
                  hide-details="auto"
                  prepend-icon="mdi-account"
                  label="Type"
                  :errors="isSubmitted && $v.form.type.$invalid ? ['Type is required'] : []"
                  v-model="$v.form.type.$model"
                  dense
                  outlined
                >
                  <template v-slot:append v-if="!isEdit && canUpdate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on" @click="isEdit = true">
                          <v-icon small color="primary">mdi-pencil</v-icon>
                        </a>
                      </template>
                      <span>Edit Type</span>
                    </v-tooltip>
                  </template>
                </BaseAutocomplete>
              </v-col>
              <v-col v-if="sopID && !generatingNewVersion" cols="12" sm="6" class="pb-0 pl-0 pr-0">
                <BaseAutocomplete
                  v-model="version"
                  :errors="sopID && !version ? ['Please pick a version.'] : []"
                  :outlined="isEdit"
                  :items="versions"
                  :item-text="({ recordNumber }) => (recordNumber ? `v${recordNumber}.00` : 'Unassigned')"
                  label="Version Number"
                  clearable
                  type="text"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                >
                  <template v-slot:item="{ item }">
                    <template v-if="!isObject(item)">
                      <v-list-item-content v-text="item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="item.recordNumber ? `v${item.recordNumber}.00` : 'Unassigned'"></v-list-item-title>
                        <v-list-item-subtitle v-html="item.status"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </BaseAutocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Procedure -->
      <v-expansion-panel v-if="!$v.form.id.$model || version || ($v.form.id.$model && generatingNewVersion)">
        <v-expansion-panel-header color="terciary">
          <template v-slot:default>
            <v-toolbar color="transparent" flat height="40" dark>
              <p class="subtitle-1 mb-0">
                Version Information
                {{ formattedVersionNumber && `(${formattedVersionNumber})` }}
              </p>

              <v-divider vertical inset class="mx-3"></v-divider>

              <v-menu
                offset-y
                v-if="sopID && $v.versionForm.id.$model && $v.versionForm.status.$model == 'Requesting Approval' && canUpdate"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :loading="isVersionDelete" @click="stopPropagation($event)" class="mx-1" small outlined
                    >Delete Version</v-btn
                  >
                </template>

                <v-card class="pa-2">
                  <p class="text-center v-label mb-1">
                    Are you sure you want to delete version
                    {{ formattedVersionNumber }}?
                  </p>
                  <v-card-actions
                    :class="{
                      'd-flex justify-center': true,
                      'flex-column': !$vuetify.breakpoint.mdAndUp,
                    }"
                  >
                    <v-btn
                      dark
                      color="red"
                      small
                      @click.prevent="
                        deleteVersionRecord({
                          procedureId: $v.form.id.$model,
                          versionId: $v.versionForm.id.$model,
                          pictures: $v.versionForm.pictures.$model,
                        })
                      "
                      >Delete</v-btn
                    >
                    <v-btn dark color="green" small>Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-toolbar>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="formContainerClasses" color="white">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <!-- Make it string doin a migration -->
                <BaseAutocomplete
                  :items="sopStatus"
                  :outlined="isEdit"
                  :readonly="!isEdit"
                  :clearable="isEdit"
                  v-model="$v.versionForm.status.$model"
                  item-text="text"
                  item-value="text"
                  label="Status"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                  :errors="isSubmitted && $v.versionForm.status.$invalid ? ['Status is required.'] : []"
                >
                  <template v-slot:append v-if="!isEdit && canUpdate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on" @click="isEdit = true">
                          <v-icon small color="primary">mdi-pencil</v-icon>
                        </a>
                      </template>
                      <span>Edit Status</span>
                    </v-tooltip>
                  </template>
                </BaseAutocomplete>
              </v-col>

              <v-col cols="12" sm="6" class="pb-0">
                <BaseAutocomplete
                  :outlined="isEdit"
                  :readonly="!isEdit"
                  :clearable="isEdit"
                  :items="users"
                  item-text="displayName"
                  item-value="id"
                  v-model="$v.versionForm.approver.$model"
                  :errors="isSubmitted && $v.versionForm.approver.$invalid ? ['Approver is required.'] : []"
                  type="text"
                  label="Approver"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                >
                  <template v-slot:append v-if="!isEdit && canUpdate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on" @click="isEdit = true">
                          <v-icon small color="primary">mdi-pencil</v-icon>
                        </a>
                      </template>
                      <span>Edit Approver</span>
                    </v-tooltip>
                  </template>
                </BaseAutocomplete>
              </v-col>

              <!-- Procedure -->
              <v-col cols="12" class="pb-0">
                <div class="d-flex justify-end align-center" v-if="!isEdit && canUpdate">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a v-bind="attrs" v-on="on" @click="isEdit = true">
                        <v-icon small color="primary">mdi-pencil</v-icon>
                      </a>
                    </template>
                    <span>Edit Procedure</span>
                  </v-tooltip>
                </div>

                <div class="black--text" v-html="$v.versionForm.procedure.$model" v-if="!isEdit"></div>

                <template v-else>
                  <BaseRichTextEditor
                    v-model="$v.versionForm.procedure.$model"
                    label="SOP/Policy Description"
                    outlined
                    placeholder="Describe the SOP/Policy here."
                    :errors="isSubmitted && $v.versionForm.procedure.$invalid ? ['Procedure is required.'] : []"
                  />
                </template>
              </v-col>

              <v-col>
                <BaseAutocomplete
                  :items="divisionDepartments"
                  label="Interested Group/Department"
                  multiple
                  :outlined="isEdit"
                  :readonly="!isEdit"
                  v-model="$v.versionForm.interestedGroups.$model"
                  item-text="text"
                  item-value="id"
                >
                  <template v-slot:append v-if="!isEdit && canUpdate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on" @click="isEdit = true">
                          <v-icon small color="primary">mdi-pencil</v-icon>
                        </a>
                      </template>
                      <span>Edit Interested Group/Department</span>
                    </v-tooltip>
                  </template>
                </BaseAutocomplete>
              </v-col>

              <v-col>
                <BaseTextArea
                  v-model="$v.versionForm.comments.$model"
                  label="Comments"
                  :outlined="isEdit"
                  :readonly="!isEdit"
                  prepend-icon="mdi-text-box-outline"
                  hide-details="auto"
                  class="mb-3"
                >
                </BaseTextArea>
              </v-col>

              <v-col cols="12">
                <BaseFileManager
                  :existingFiles="$v.versionForm.pictures.$model"
                  v-model="pictures"
                  :allowedFileTypes="['images', 'videos']"
                  label="Video/Photo"
                  prepend-icon="mdi-tooltip"
                  class="mb-3"
                  :disabled="!isEdit || !canUpdate"
                  @delete="
                    $v.versionForm.id.$model
                      ? deleteVersionImage({
                          url: $event,
                          procedureId: $v.form.id.$model,
                          versionId: $v.versionForm.id.$model,
                          pictures: $v.versionForm.pictures.$model,
                        })
                      : deleteClonedImage({ url: $event })
                  "
                >
                </BaseFileManager>
                <BaseInput
                  v-model="$v.versionForm.embedURL.$model"
                  :outlined="isEdit"
                  :readonly="!isEdit"
                  type="text"
                  label="Video URL"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                >
                  <template v-slot:append v-if="!isEdit && canUpdate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on" @click="isEdit = true">
                          <v-icon small color="primary">mdi-pencil</v-icon>
                        </a>
                      </template>
                      <span>Edit Embed URL</span>
                    </v-tooltip>
                  </template>
                </BaseInput>
                <div v-if="embedURL" style="position: relative; padding-bottom: 55.3125%; height: 0">
                  <iframe
                    :src="embedURL"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    :style="{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }"
                  ></iframe>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <BaseInput
                  v-if="
                    ($v.versionForm.modifiedDate.$model || $v.versionForm.createdDate.$model) &&
                    $v.versionForm.status.$model === 'Approved' &&
                    $v.versionForm.id.$model
                  "
                  :outlined="isEdit"
                  readonly
                  :model-value="moment($v.versionForm.modifiedDate.$model || $v.versionForm.createdDate.$model).format('D/M/YY')"
                  label="Approved Date"
                  prepend-icon="mdi-calendar-clock"
                  hide-details="auto"
                  input-class="mb-3"
                >
                </BaseInput>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- System Information -->
      <v-expansion-panel v-if="modificationData.createdBy || modificationData.modifiedBy">
        <v-expansion-panel-header color="terciary">
          <template v-slot:default>
            <v-toolbar color="transparent" flat height="40" dark>
              <p class="subtitle-1 mb-0">System Information</p>
            </v-toolbar>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="formContainerClasses" color="white">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <BaseInput
                  v-if="modificationData.createdBy"
                  :model-value="
                    webappUsers.find(({ id }) => $v.versionForm.createdBy.$model == id) &&
                    webappUsers.find(({ id }) => $v.versionForm.createdBy.$model == id).displayName
                  "
                  :outlined="isEdit"
                  type="text"
                  label="Created By"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                />
                <BaseInput
                  v-if="modificationData.modifiedBy"
                  :model-value="
                    webappUsers.find(({ id }) => $v.versionForm.modifiedBy.$model == id) &&
                    webappUsers.find(({ id }) => $v.versionForm.modifiedBy.$model == id).displayName
                  "
                  :outlined="isEdit"
                  readonly
                  type="text"
                  label="Modified By"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                />
              </v-col>

              <v-col cols="12" sm="6" class="pb-0">
                <BaseInput
                  v-if="modificationData.createdBy"
                  :model-value="$v.versionForm.createdDate.$model && moment($v.versionForm.createdDate.$model).format('M/D/YY h:mm A')"
                  :outlined="isEdit"
                  readonly
                  type="text"
                  label="Created Date"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                />

                <BaseInput
                  v-if="modificationData.modifiedBy"
                  :model-value="$v.versionForm.modifiedDate.$model && moment($v.versionForm.modifiedDate.$model).format('M/D/YY h:mm A')"
                  :outlined="isEdit"
                  readonly
                  type="text"
                  label="Modified Date"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <BaseInput
                  v-if="modificationData.createdBy"
                  :model-value="$v.form.id.$model"
                  :outlined="isEdit"
                  readonly
                  type="text"
                  label="SOP/Policy ID"
                  prepend-icon="mdi-account-details-outline"
                  hide-details="auto"
                  class="mb-3"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-app-bar class="lightedison__fixed-toolbar" bottom color="terciary" v-if="isEdit" style="position: sticky; z-index: 1">
      <div class="text-center">
        <v-btn small color="#C9E2FF" @click="submitSOP()" :loading="isLoading">Submit </v-btn>

        <v-btn small dark @click="resetForm()" outlined :disabled="isLoading">Cancel </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { validations } from './validations.js';
import { getDivisionWithDepartments, getFirestorePicklist } from '@/services/pickers/PickersService.js';
import { currentUser, firestoreDocument, getIdFromRecord, padNumber, resetModel, stopPropagation } from '@/services/utils/Utils.js';
import firebase from 'firebase';
import { deleteImages, getBlobFromURL, pictureDownload } from '@/services/firebase/StorageService.js';
import { AdminRole, SuperAdminRole, companyName } from '@/config/constants';

export default {
  name: 'SOPForm',
  validations,
  props: {
    sopID: String,
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.formObject(),
      versionForm: this.versionFormObject(),

      //
      initialState: {},
      initialVersionState: {},
      types: ['SOP', 'Policy'],

      //
      user: {},

      //
      isSubmitted: false,
      isLoading: false,
      pictures: [],

      //
      showUnassignedFields: false,

      //
      isEdit: false,

      // Picklists
      sopStatus: [],
      divisionWithDepartments: [],

      // Version Controller
      version: null,
      generatingNewVersion: false,
      versionStatus: 'Requesting Approval',

      //
      isVersionDelete: false,

      //
      panels: [0, 1, 2],

      //
      isTemplateLoading: false,

      // To populate the system fields
      webappUsers: [],

      // Is the form opened for an update
      isRecordLoading: true,
    };
  },
  watch: {
    async modificationData({ createdBy, modifiedBy }) {
      try {
        if (createdBy && !this.webappUsers.find(({ id }) => id == createdBy)) {
          const user = await this.fetchUser(createdBy);
          if (user) this.webappUsers.push(user);
        }
        if (modifiedBy && !this.webappUsers.find(({ id }) => id == modifiedBy)) {
          const user = await this.fetchUser(modifiedBy);
          if (user) this.webappUsers.push(user);
        }
      } catch (eror) {
        console.log(error);
      }
    },
    async version(versionId) {
      if (this.generatingNewVersion) {
        return;
      }

      if (!versionId) {
        this.$v.versionForm.$model = this.versionFormObject();
        this.initialVersionState = this.versionFormObject();
        return;
      }

      await this.getVersion({
        procedureId: this.$v.form.id.$model,
        versionId,
      });

      this.resetPanels();
    },
    status(value) {
      this.versionStatus = value;
    },
  },
  async mounted() {
    //
    this.fetchUsers();
    if (this.sopID) {
      this.getSOP(this.sopID);
      this.fetchVersions(this.sopID);
    } else {
      this.isEdit = true;
      this.isRecordLoading = false;
    }

    // Picklists
    Promise.all([this.getFirestorePicklist('sopStatus')])
      .then(([sopStatus]) => {
        this.sopStatus = [...sopStatus];
      })
      .catch((err) => {
        console.log(err);
        swal(
          'Error!',
          `Something went wrong when retrieving the picklist, please try again or contact with ${companyName}'s IT Department`,
          'error',
        );
      });

    getDivisionWithDepartments()
      .then((divisions) => {
        this.divisionWithDepartments = divisions;
      })
      .catch((error) => {
        const errorObject = {
          type: 'error',
          error,
        };
        this.addNotification(errorObject);
      });

    const { currentUser } = firebase.auth();
    currentUser.getIdTokenResult().then(({ claims }) => {
      this.user = {
        ...currentUser.uid,
        ...claims,
      };
    });
  },

  methods: {
    ...mapActions('notifications', ['addNotification']),
    ...mapActions('sops', ['fetchSOP', 'saveSOP', 'saveVersion', 'fetchVersions', 'fetchVersion', 'deleteVersion']),
    ...mapActions('users', ['fetchUsers', 'fetchUser']),

    //
    getFirestorePicklist,
    getIdFromRecord,
    resetModel,
    currentUser,
    //
    firestoreDocument,
    stopPropagation,
    deleteImages,

    // Delete Video/Photo Files
    deleteVersionImage({ url, procedureId, versionId, pictures }) {
      if (!url || !procedureId || !versionId || !pictures.length) {
        throw new Error('Invalid fields');
      }

      const versionDocument = firestoreDocument('sops')(procedureId).collection('versions').doc(versionId);

      return deleteImages([{ url }])
        .then(() => pictures.filter(({ url: pictureUrl }) => pictureUrl !== url))
        .then((pictures) => versionDocument.update({ pictures }))
        .then(() => {
          const successObject = {
            type: 'success',
            message: 'Image deleted successfully',
          };
          this.addNotification(successObject);
          this.getSOP(this.sopID);
          this.getVersion({ procedureId, versionId });
        })
        .catch((error) => {
          const errorObject = {
            type: 'error',
            error,
          };

          this.addNotification(errorObject);
        });
    },
    deleteClonedImage({ url } = {}) {
      this.$v.versionForm.pictures.$model = this.$v.versionForm.pictures.$model.filter((pictureUrl) => url !== pictureUrl);
    },
    resetPanels() {
      this.panels = [];
      this.panels = [0, 1, 2];
    },
    deleteVersionRecord({ procedureId, versionId, pictures = [] }) {
      this.isVersionDelete = true;

      this.deleteVersion({ procedureId, versionId, pictures })
        .then(() => {
          this.version = null;
          this.addNotification({
            type: 'success',
            message: 'Version Deleted Sucessfully',
          });
        })
        .catch((error) => {
          this.addNotification({
            type: 'error',
            error,
          });
        })
        .finally(() => {
          this.isVersionDelete = false;
        });
    },
    isObject(value) {
      return value && typeof value == 'object' && !Array.isArray(value);
    },
    generateNewVersion({ fromCurrent } = {}) {
      this.generatingNewVersion = true;
      this.version = null;
      this.isEdit = true;

      if (fromCurrent && this.$v.versionForm.id.$model) {
        const { id, createdBy, createdDate, modifiedBy, modifiedDate, recordNumber, ...data } = JSON.parse(
          JSON.stringify(this.$v.versionForm.$model),
        );

        //
        const clonedVersion = { ...data, status: 'Requesting Approval' };
        this.$v.versionForm.$model = this.versionFormObject();
        this.$v.versionForm.$model = this.resetModel(this.$v.versionForm.$model, clonedVersion);
      } else {
        this.$v.versionForm.$model = this.versionFormObject();
      }

      this.resetPanels();
    },
    //
    systemFields: ({ id, userId, date = new Date().toISOString() }) => ({
      [id ? 'modifiedBy' : 'createdBy']: userId,
      [id ? 'modifiedDate' : 'createdDate']: date,
    }),

    // Form
    formObject() {
      return {
        // Record ID
        id: null,
        recordNumber: null,

        // Fields on layout
        name: null,
        type: null,
        versionCount: 0,

        // System Fields
        createdBy: null,
        createdDate: null,
        modifiedBy: null,
        modifiedDate: null,
      };
    },
    versionFormObject() {
      return {
        id: null,
        recordNumber: null,

        // Status & Version Number
        status: 'Requesting Approval',

        // Procedure
        procedure: null,
        interestedGroups: [],
        comments: null,
        approver: null,

        pictures: [],
        embedURL: null,

        // Version Record System Fields
        createdBy: null,
        createdDate: null,
        modifiedBy: null,
        modifiedDate: null,
      };
    },

    // Submit button
    async submitSOP() {
      this.isSubmitted = true;
      if (this.$v.form.$invalid) {
        console.log('SOP/Policy is Invalid.');
        return;
      }

      if (this.$v.versionForm.$invalid) {
        console.log('Version is Invalid.');
        return;
      }

      //
      const { $model: formModel } = this.$v.form;
      const { $model: versionFormModel } = this.$v.versionForm;

      //
      const date = new Date().toISOString();

      // SOP/Policy Data
      const { id, ...formObject } = {
        ...formModel,
        ...this.systemFields({
          id: formModel.id,
          userId: this.currentUser().uid,
          date,
        }),
      };

      // Version Data
      const { id: versionId, ...versionFormObject } = {
        ...this.initialVersionState,
        ...versionFormModel,
        ...this.systemFields({
          id: versionFormModel.id,
          userId: this.currentUser().uid,
          date,
        }),
      };

      const saveOptions = {
        id,
        data: formObject,
        collectionName: 'sops',
        metadataDocumentID: 'sopsMetadata',
      };

      try {
        // Downloading and uploading the image of the cloned version to avoid conflicts.
        if (this.generatingNewVersion) {
          const downloadedPictures = await pictureDownload(getBlobFromURL)(this.$v.versionForm.pictures.$model.map(({ url }) => url));
          this.pictures = [...this.pictures, ...downloadedPictures];
          versionFormObject.pictures = [];
        }

        //
        this.isLoading = true;
        await this.saveSOP(saveOptions)
          .then((id) => {
            const saveOptions = {
              id: versionId,
              procedureId: id,
              data: versionFormObject,
              pictures: this.pictures,
              parentFolder: `picture_uploads/sops/${id}`,
            };

            return this.saveVersion(saveOptions);
          })
          .then(() => {
            this.$v.form.$model = this.formObject();
            this.isLoading = false;
            this.isSubmitted = false;

            const successObject = {
              type: 'success',
              message: 'SOP/Policy Record saved successfully',
            };
            this.addNotification(successObject);
            if (this.embedded) this.$emit('close');
            else this.$router.push(`/sops`);
          });
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.isSubmitted = false;
        const errorObject = {
          type: 'error',
          error,
        };

        this.addNotification(errorObject);
      }
    },

    //
    resetForm() {
      if (this.sopID) {
        this.generatingNewVersion = false;
        this.$v.form.$model = this.resetModel(this.$v.form.$model, this.initialState);
        this.isEdit = false;
      } else {
        if (this.embedded) this.$emit('close');
        else this.$router.push('/sops');
      }
    },

    //
    getValue(value) {
      if (typeof value === 'object') {
        return JSON.stringify(value);
      } else {
        return value + '';
      }
    },
    getSOP(id) {
      return this.fetchSOP(id)
        .then((record) => {
          this.$v.form.$model = this.resetModel(this.$v.form.$model, record);
          this.initialState = record;
          this.isRecordLoading = false;

          //
          if (this.$route.query['version']) {
            this.version = this.$route.query['version'];
          }
        })
        .catch((error) => {
          this.addNotification({
            type: 'error',
            message: error,
          });
        });
    },
    getVersion({ procedureId, versionId } = {}) {
      return this.fetchVersion({ procedureId, versionId })
        .then((record) => {
          this.$v.versionForm.$model = this.resetModel(this.$v.versionForm.$model, record);
          this.initialVersionState = record;
        })
        .catch(() => {
          this.addNotification({
            type: 'error',
            message: error,
          });
        });
    },

    //
    toCapitalizedWords(name) {
      const words = name.match(/[A-Za-z][a-z]*/g) || [];
      return words.map(this.capitalize).join(' ');
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    },
  },

  computed: {
    ...mapGetters('users', ['users']),
    ...mapGetters('sops', ['versions']),
    embedURL() {
      const embedURL = this.$v.versionForm.embedURL.$model || '';
      const updatedURL = embedURL.replace('share', 'embed');

      try {
        return new URL(updatedURL);
      } catch (error) {
        return null;
      }
    },
    canUpdate() {
      const { createdBy, approver } = this.$v.versionForm.$model;

      return (
        ([AdminRole, SuperAdminRole].includes(this.user.role) || [createdBy, approver].includes(this.user.uid)) &&
        !['Approved', 'Archived'].includes(this.$v.versionForm.status.$model)
      );
    },
    divisionDepartments() {
      const divisionDepartments = this.divisionWithDepartments.reduce(
        (acc, division) => [
          ...acc,
          ...division.departments.map((department) => ({
            text: `${division.text} - ${department.text}`,
          })),
        ],
        [],
      );
      return divisionDepartments;
    },
    canGenerate() {
      return this.sopID && !this.generatingNewVersion && !this.existingRequestingApprovalVersion;
    },
    existingRequestingApprovalVersion() {
      return this.versions.some(({ status }) => status == 'Requesting Approval');
    },
    formattedVersionNumber() {
      return this.$v.versionForm.recordNumber.$model ? `v${this.$v.versionForm.recordNumber.$model}.00` : '';
    },
    modificationData() {
      return {
        createdBy: this.$v.versionForm.createdBy.$model,
        modifiedBy: this.$v.versionForm.modifiedBy.$model,
      };
    },
    formContainerClasses() {
      return {
        'px-10': this.$vuetify.breakpoint.smAndUp,
        'px-1': !this.$vuetify.breakpoint.smAndUp,
        'pt-3': this.$vuetify.breakpoint.smAndUp,
        'pt-5': !this.$vuetify.breakpoint.smAndUp,
        'pb-3': true,
      };
    },
    status() {
      return this.$v.versionForm.status.$model;
    },
    breadcrumbsItems() {
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/home',
        },
        {
          text: 'SOPs & Policies',
          exact: true,
          disabled: false,
          to: '/sops',
        },
        {
          text: this.$v.form.name.$model ? this.$v.form.name.$model : 'New SOP/Policy',
        },
      ];
    },
    subtitle() {
      return `Check the details for the SOP/Policy below.`;
    },
    title() {
      const { recordNumber, type } = this.$v.form.$model;
      const recordName = `${type == 'SOP' ? 'SOP' : 'POL'}-${padNumber(recordNumber, 4)}`;
      if (recordNumber !== null && Number.isInteger(+recordNumber)) {
        return recordName;
      } else {
        return 'New SOP/Policy Record';
      }
    },
  },
};
</script>

<style>
.light-edison__sop-form .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto !important;
}

.light-edison__sop-form .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.light-edison__sop-form .v-expansion-panel-header {
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: auto !important;
}

.light-edison__sop-form .v-expansion-panel {
  background: #1c4060 !important;
  color: #fff !important;
}

.light-edison__sop-form .v-expansion-panel-header__icon .v-icon {
  color: #fff !important;
}
</style>
