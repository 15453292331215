import { firestoreDocument, picklistFormatToArray } from '@/services/utils/Utils.js';
import { companyName } from '@/config/constants';

export const getFirestorePicklist = async (picklistName) => {
  try {
    const picklistDocument = firestoreDocument('picklistsNew2')(picklistName);
    const picklistQuerySnapshot = await picklistDocument.get();
    const { elements } = picklistQuerySnapshot.data();
    const picklist = picklistFormatToArray(elements);
    return picklist;
  } catch (error) {
    swal(
      'Error!',
      `Something went wrong retrieving "${picklistName}" picklist, please try again or contact with ${companyName}'s IT Division`,
      'error',
    );
    console.log(error);
    return [];
  }
};

export const isDeprecatedGroup = ({ id }) => {
  if (!id) {
    throw new Error('id is required');
  }
  const deprecatedGroupIds = ['SM', 'E', 'FO', 'S', 'O', 'F'];
  return deprecatedGroupIds.includes(id);
};

export const getDivisionWithDepartments = async () => {
  try {
    const divisionsDocument = firestoreDocument('picklistsNew2')('divisions');
    const { elements } = (await divisionsDocument.get()).data();
    const newDivisions = picklistFormatToArray(elements);

    const departmentPromises = newDivisions.map(({ id }) => firestoreDocument('picklistsNew2')(`${id.toLowerCase()}Departments`).get());

    const allDepartments = await Promise.all(departmentPromises);
    const mappedDepartments = allDepartments.map((querySnapshot) => {
      const { elements } = querySnapshot.data();

      return picklistFormatToArray(elements);
    });

    for (const index in newDivisions) {
      newDivisions[index]['departments'] = mappedDepartments[index];
    }

    return newDivisions;
  } catch (error) {
    swal('Error!', `Something went wrong, please try again or contact with ${companyName}'s IT Division`, 'error');
    console.log(error);
  }
};
