import localforage from 'localforage';
import { CacheEntry, isExpired } from './cacheEntry';

export class CacheService {
  public initialize(): void {
    localforage.config({
      name: 'cache',
      storeName: 'kv-storage',
      driver: localforage.INDEXEDDB,
    });
  }

  public async get<T>(cacheKey: string): Promise<T | null> {
    const entry = await localforage.getItem<CacheEntry<T>>(cacheKey);

    if (entry) {
      if (isExpired(entry)) {
        console.log(`Cache expired for ${cacheKey}`);
        await this.delete(cacheKey);
        return null;
      }
      return entry.data as T;
    }

    return null;
  }

  public async set<T>(cacheKey: string, data: T, ttlMins?: number): Promise<void> {
    const entry = new CacheEntry<T>(data, ttlMins);
    await localforage.setItem(cacheKey, entry);
  }

  public async delete(cacheKey: string): Promise<void> {
    await localforage.removeItem(cacheKey);
  }

  public async clear(): Promise<void> {
    await localforage.clear();
  }
}
