import { v4 } from 'uuid';

export type Callback = (data: unknown) => void;

// TODO-676: How are we handling the observer IDs, do we need them, or are they redundant with the requestKey?
// case where two consumers want a true onSnapshot subscription, not just one time data???
export class Observer {
  id: string;
  callback: Callback;

  constructor(callback: Callback) {
    this.id = v4();
    this.callback = callback;
  }
}

export class Snapshot {
  key: string;
  data: unknown;
  error: Error | null = null;

  observers: Observer[];

  ttlMins: number;
  ttlTimeout: NodeJS.Timeout | null = null;

  unsubscribe: () => void;

  constructor(key: string, unsubscribe: () => void, ttlMins: number) {
    this.key = key;
    this.observers = [];
    this.ttlMins = ttlMins;
    this.unsubscribe = unsubscribe;
  }
}
