import { ServiceContainer } from '@/services/serviceContainer';

type ServiceContructorArray<T extends unknown[]> = { [K in keyof T]: new () => T[K] };

export function useServices<T extends unknown[]>(...services: ServiceContructorArray<T>): T {
  return services.map((service) => ServiceContainer.get(service)) as T;
}

export function useService<T>(singleton: new () => T): T {
  return ServiceContainer.get(singleton);
}
