import firebase from 'firebase';
import escape from 'lodash/escape.js';
import get from 'lodash/get.js';
import { companyName } from '@/config/constants';

/**
 * Capitalize First Letter
 * @param {string} value - The word to capitalize
 * @returns {string} - The value with the first letter capitalized
 */
function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Incremeting number
 * @param {number|string} n
 * @param {number} width
 * @param {number|string} [z="0"]
 * @returns {*} - The incremented number
 */
function padNumber(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '.' + match[2] + '.' + match[3];
  }
  return null;
}

/**
 * Curried function to take a document from a specific collection on firebase firestore
 * @param {*} collectionName - The Firebase collection Name
 * @returns
 */
const firestoreDocument = (collectionName) => (documentId) => {
  const firestore = firebase.firestore();
  const collection = firestore.collection(collectionName);
  return documentId ? collection.doc(documentId) : collection.doc();
};

// It checks if an object is empty
function isEmptyObject(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

/**
 * System Fields, these properties are intended to be on every record that we have on our database
 * to keep track of who created the record and who's the last person who update the record
 * @param {*} id - Record ID or any boolean like indicator that this record exists
 * @param {string} displayName - User Name
 * @param {string} uid - User UID
 * @param {string} dateISOString - Date formatted
 * @returns
 */
const systemFields = (id, { displayName, uid }, dateISOString = new Date().toISOString()) => {
  return {
    [id ? 'modifiedBy' : 'createdBy']: {
      name: displayName,
      id: uid,
    },
    [id ? 'modifiedDate' : 'createdDate']: dateISOString,
  };
};

/**
 * Checks if a given value is of type object
 * @param {string} value - The value to be evaluated
 * @returns { boolean }
 */
const isObject = (value) => value && typeof value == 'object' && !Array.isArray(value);

/**
 *
 * @param  {...any} fns
 * @returns
 */
const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((acc, fn) => fn(acc), x);

/**
 * Function to prevent propagation
 * @param {*} event
 */
const stopPropagation = (event) => {
  event.stopPropagation();
};

/**
 * Assign a color to a task status
 * @param {*} status
 * @returns
 */
const colorByStatus = (status = '') =>
  ({
    closed: 'green',
    open: 'yellow',
    untouched: 'red',
  })[status.toLowerCase()];

/**
 * Picklist showing the hours AM and PM every 15 minutes
 * @returns {times}
 */
const loadTimes = () => {
  var minutes = ['00', '15', '30', '45'];
  var timeSection = ['AM', 'PM'];
  var times = [];
  for (var q = 0; q < timeSection.length; q++) {
    for (var i = 0; i < 12; i++) {
      var hour = i;
      if (i == 0) {
        hour = 12;
      }
      for (var j = 0; j < minutes.length; j++) {
        times.push(hour + ':' + minutes[j] + ' ' + timeSection[q]);
      }
    }
  }
  return times;
};

/**
 * Error Message
 * @param {*} error
 * @returns
 */
const getErrorMessage = ({ error }) => error || `Something went wrong, please try again or contact with ${companyName}'s IT Division`;

/**
 * Gets a <file> event and returns some data
 * @param {*} event
 * @param {*} allowedImageFormats - Files formats
 * @returns
 */
const getDataFromFileEvent = (event, allowedImageFormats = ['jpg', 'jpeg', 'heic', 'png', 'pdf']) => {
  return new Promise((resolve, reject) => {
    /**
     * Getting the file from the event
     */
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    /**
     * Checking if it is a valid file type first
     */
    const extension = file.name.split('.').pop();
    // const allowedImageFormats = ["jpg", "jpeg", "heic", "png", "pdf"];
    if (!allowedImageFormats.includes(extension.toLowerCase())) {
      reject({
        error: 'Only the formats "JPG", "JPEG", "HEIC", "PNG" and "PDF" are allowed.',
        type: 'Unsupported Extension',
      });
    }

    reader.onloadend = function (e) {
      const fileDataId = new Date().getTime();

      const fileData = {
        id: fileDataId,
        fileName: file.name,
        data: e.target.result,
        file,
      };

      resolve(fileData);
    };
  });
};

/**
 * Current user
 * @returns - User information
 */
const currentUser = () => {
  const auth = firebase.auth();
  const { uid, displayName, email, phoneNumber } = auth.currentUser;
  return { uid, displayName, email, phoneNumber };
};

const currentUserToken = () => {
  const auth = firebase.auth();
  return auth.currentUser.getIdToken(true);
};

/**
 * Getting Firestore Collection
 * @param {*} collectionName - The collection name
 * @returns - Collection
 */
const getFirestoreCollection = (collectionName) => {
  const firestore = firebase.firestore();
  return firestore.collection(collectionName);
};
/**
 * Converting picklist format to array
 * @param {*} items
 * @returns
 */
const picklistFormatToArray = (items) => {
  return [...items].map((item) => {
    const [value] = Object.keys(item);

    return {
      text: item[value],
      id: value,
    };
  });
};

/**
 * Reset data
 * @param {Object} vuelidateModel
 * @param {Object} referenceObject
 * @param {*} excludedProperties
 * @returns {Object} newObject
 */
const resetModel = (vuelidateModel = {}, referenceObject = {}, excludedProperties = []) => {
  const newObject = {};

  for (const key in vuelidateModel) {
    if (excludedProperties.includes(key) || !referenceObject.hasOwnProperty(key)) {
      if (Array.isArray(vuelidateModel[key])) {
        newObject[key] = [...vuelidateModel[key]];
      } else if (vuelidateModel[key] && typeof vuelidateModel[key] === 'object') {
        newObject[key] = resetModel(vuelidateModel[key], vuelidateModel[key]);
      } else {
        newObject[key] = vuelidateModel[key];
      }
      continue;
    }

    if (Array.isArray(referenceObject[key])) {
      newObject[key] = [...referenceObject[key]];
    } else if (referenceObject[key] && typeof referenceObject[key] === 'object') {
      newObject[key] = resetModel(referenceObject[key], referenceObject[key]);
    } else {
      newObject[key] = referenceObject[key];
    }
  }

  return newObject;
};

/**
 * Getting Opportunity Information from Record
 * @param {*}
 * @returns
 */
const getOpportunityFromRecord = (
  { opportunityRecordID, opportunityName } = {
    opportunityRecordID: null,
    opportunityName: null,
  },
) => ({ name: opportunityName, id: opportunityRecordID });

/**
 * Converts a query snapshot into an array (Only for arrays of records instead of a single record)
 * @param {*} querySnapshot
 * @returns items
 */
const querySnapshotToArray = (querySnapshot) => {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({
      ...doc.data(),
      id: doc.id,
    });
  });

  return items;
};

// Firestore listeners on functions
const querySnapshotToData = (querySnapshot) => ({
  ...querySnapshot.data(),
  id: querySnapshot.id,
});

/**
 * Getting Contact Information from record
 * @param {*} event
 * @returns
 */
const getContactFromRecord = (event) => {
  const { fullName, name, id } = event || {
    name: null,
    fullName: null,
    id: null,
  };
  return { name: name || fullName, id };
};

/**
 * Getting ID from record
 * @param {*} event
 * @returns
 */
const getIdFromRecord = (event) => {
  return event && event.id ? event.id : null;
};

/**
 * Handling picklist by text and ID
 * @param {*} event
 * @returns
 */
const handlePicklist = (event) => {
  const { text, id } = event || {
    text: null,
    id: null,
  };
  return { text, id };
};

/**
 * Date error notification
 * @param {*} model
 * @returns
 */
const dateErrors = (model) => {
  const errors = [];
  model.required === false && errors.push('Field is required');
  model.greaterThanCurrent === false && errors.push("Date can't be set before the current date.");
  return errors;
};

/**
 * Getting Employee Name information
 * @param {*} contactID
 * @param {*} contacts
 * @returns
 */
const getEmployeeName = (contactID, contacts) => {
  if (!contactID) {
    return null;
  }

  const contact = contacts.find(({ id }) => contactID === id);
  return contact && contact.fullName;
};

/**
 *
 * @param {*} size
 * @returns
 */
const separatedSpacesIDSReducer = (size) => (acc, item) => {
  if (acc[acc.length - 1].length < size) {
    acc[acc.length - 1].push(item);
    return acc;
  } else {
    return [...acc, [item]];
  }
};

/**
 * Splits an array into several arrays with the length of "size"
 * Input: an array for the reducer, the max length for every splitted array
 * The final result is an array of arrays
 * It must be used with [[]] as the initial value for the accumulator
 * @param {*} size
 * @returns
 */
const arraySplitReducer = (size) => (acc, item) => {
  if (acc[acc.length - 1].length < size) {
    acc[acc.length - 1].push(item);
    return acc;
  } else {
    return [...acc, [item]];
  }
};

/**
 * Getting Records from ID
 * @param {*} collectionName
 * @param {*} propertyName
 * @param {*} idsArray
 * @returns
 */
const getRecordsFromIdArray = (collectionName, propertyName, idsArray) =>
  new Promise((resolve, reject) => {
    const firestore = firebase.firestore();
    const spacesCollection = firestore.collection(collectionName);

    const requestBatches = idsArray.reduce(separatedSpacesIDSReducer(10), [[]]);
    const spacesReducer = (acc, querySnapshot) => [...acc, ...querySnapshotToArray(querySnapshot)];

    propertyName = propertyName || firebase.firestore.FieldPath.documentId();

    Promise.all(
      requestBatches.map((batch) => {
        if (batch.length) {
          return spacesCollection.where(propertyName, 'in', batch).get();
        } else {
          return [];
        }
      }),
    )
      .then((responses) => responses.reduce(spacesReducer, []))
      .then((spaces) => {
        resolve([...spaces]);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Converts an array into a map (id as property keys and data as property values)
 * @param {*} accum
 * @param {*} item
 * @returns
 */
const mapperReducer = (accum, item) => ({
  ...accum,
  [item.id]: item,
});

/**
 * Reducer for arrays
 * It takes an array of objects and makes an array
 * Creates an array with the values of the "field" property in each record
 * (Output array excludes falsy or repeated values)
 * Input: An Array of objects, accumulator's initial value must be an empty array
 * @param {*} field
 * @returns
 */
const fieldReducer =
  (field) =>
  (accum = [], record) => [...accum, ...(record[field] && !accum.includes(record[field]) ? [record[field]] : [])];

/**
 * Getter to retrieve an entire firebase collection if needed
 * @param {*} collectionName
 * @returns
 */
const getAllRecords = (collectionName) => (collectionName ? getFirestoreCollection(collectionName).get() : null);

/**
 *
 * @param {*} date
 * @returns
 */
const withoutTimezone = (date) => {
  date = date ? new Date(date) : new Date();
  const userTimezoneOffset = date.getTimezoneOffset() * (60 * 1000);
  return new Date(date.getTime() - userTimezoneOffset);
};

/**
 * Input: a firestore collection (collection)
 * an array of where statements,
 * example of statements array[["contactType", "array-contains", "Employee"]]
 * Output: the collection with the query chain included
 * @param {*} statements
 * @returns
 */
const withQuery =
  (statements = []) =>
  (collection) =>
    statements.reduce((acc, where) => acc.where(...where), collection);

/**
 * Replacing double braces
 * @param {*} str
 * @param {*} record
 * @returns
 */
const replaceDoubleBraces = (str, record) => {
  return str.replace(/{{(.+?)}}/g, (regExp, match) => get(record, match.trim()) || '');
};

const convertSearchTerm2Regex = (search, caseInsensitive = true) => {
  let _re_escape_regex = new RegExp(
    '(\\' + ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$', '^', '-'].join('|\\') + ')',
    'g',
  );
  search = search.replace(_re_escape_regex, '\\$1');
  /* For smart filtering we want to allow the search to work regardless of
   * word order. We also want double quoted text to be preserved, so word
   * order is important - a la google. So this is what we want to
   * generate:
   *
   * ^(?=.*?\bone\b)(?=.*?\btwo three\b)(?=.*?\bfour\b).*$
   */
  let a = $.map(search.match(/"[^"]+"|[^ ]+/g) || [''], function (word) {
    if (word.charAt(0) === '"') {
      let m = word.match(/^"(.*)"$/);
      word = m ? m[1] : word;
    }

    return word.replace('"', '');
  });

  search = '^(?=.*?' + a.join(')(?=.*?') + ').*$';
  return new RegExp(search, caseInsensitive ? 'i' : '');
};

const isDateFuture = (date) => new Date(date).getTime() > Date.now();

const maskedInput2html = ({ start, middle, end }) =>
  `${escape(start)}<span class="v-list-item__mask">${escape(middle)}</span>${escape(end)}`;

export {
  arraySplitReducer,
  capitalizeFirstLetter,
  colorByStatus,
  convertSearchTerm2Regex,
  currentUser,
  currentUserToken,
  dateErrors,
  fieldReducer,
  firestoreDocument,
  formatPhoneNumber,
  getAllRecords,
  getContactFromRecord,
  getDataFromFileEvent,
  getEmployeeName,
  getErrorMessage,
  getFirestoreCollection,
  getIdFromRecord,
  getOpportunityFromRecord,
  getRecordsFromIdArray,
  handlePicklist,
  isDateFuture,
  isEmptyObject,
  isObject,
  loadTimes,
  mapperReducer,
  maskedInput2html,
  padNumber,
  picklistFormatToArray,
  pipe,
  querySnapshotToArray,
  querySnapshotToData,
  replaceDoubleBraces,
  resetModel,
  stopPropagation,
  systemFields,
  withQuery,
  withoutTimezone,
};
