//Environment variables:
export const Globals = {
  get fireBaseUrl() {
    if (process.env.NODE_ENV === 'development' && process.env.APP_FIREBASE_EMULATOR === 'true') {
      return `http://localhost:5001/${process.env.APP_FIREBASE_PROJECT_ID}/us-central1/`;
    } else {
      const url = process.env.APP_FIREBASE_FUNCTIONS_URL!;
      return url.endsWith('/') ? url : `${url}/`;
    }
  },
};
export const GOOGLE_CLIENT_ID = process.env.APP_GOOGLE_CLIENT_ID;

// User & Role IDs:
export const AdminRole = '2uRlYOYN7yny6tG9a7wa';
export const SuperAdminRole = 'iGcPQKwKJTHNf5zok6I4';
export const LightingReviewerRole = 'AEgmuSwYEVat7utOfeQC';
export const InstallerRole = 'J5W0WbKNuGzIjZ2Ic23n';
export const PATHInstallerRole = 'Kw5WUEdts2OnHFAlTdno';
export const JVPartnerRole = 'JVPartner';
export const HeatherLloydUserID = 'B0GUlqD2ngfKEw4fvjWnqV58hni2';

export enum UserEmails {
  AB = 'ab@lightedison.com', // Adam Bennett
  BAC = 'bac@lightedison.com', // Bill A Clifton
  BVD = 'bvd@lightedison.com', // Becki VanDyke
  CA = 'ca@lightedison.com', // Cory Aspell
  CJS = 'cjs@lightedison.com', // Crystal J Sheridan
  EJ = 'ej@lightedison.com', // Emily Jahnke
  HL = 'hl@lightedison.com', // Heather Lloyd
  KL = 'kl@lightedison.com', // Kate Lavicky
  ML = 'ml@lightedison.com', // Matt Lowell
  SMS = 'sms@lightedison.com', // Shawn M Stutzman
}

export enum UserIds {
  JMJ = 'F2bN4HL9NkDhOwapPPtl', //John Jordan
  CA = '9g9fOFWo7Xed70lkDAwn', //Cory Aspell
  RC = '2ZpqNGYklXaT0uZTN8xq', //Russell Cordum
  DW = 'UtMrjlwT3gz9zgXOluhU', //Derek Westveer
  SR = 'jubfomZdelANl9peGRGL', //Sureka Richmond
  TMJ = 'TWvsoNjtsHhv8ZtkdUUa', //Tim Johnson
  SMS = 'CY4TFHcdwaxkpLeL4LQ2', //Shawn M Stutzman
}

// Company Information:
export const companyName = 'LightEdison';
export const companyWebsite = 'https://www.lightedison.com';
export const gridBaseURL = 'https://grid.lightedison.com/#/';
export const companyEmailDomain = '@lightedison.com';
export const LightEdisonLogo =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/logo%2Flightedison-llight.png?alt=media&token=035defe5-3774-4468-9667-d69cde4ad050';
export const LightEdisonLogo2 =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%20Logo%2BTag%20Hex%20300_6-in.png?alt=media';
export const LightEdisonLogo3 =
  'https://firebasestorage.googleapis.com/v0/b/lightedison-devserver.appspot.com/o/0.png?alt=media&token=ae12ac21-5b38-4293-847e-95b67889a30f';
export const LightEdisonWiki = 'https://sites.google.com/a/lightedison.com/internal-lightedison-wiki/';
export const serviceEmail = 'cst@lightedison.com';
export const logisticsEmail = 'logistics@lightedison.com';
export const accountsPayableEmail = 'ap@lightedison.com';
export const rfiEmail = 'rfi@lightedison.com';
export const issueEmail = 'issue@lightedison.com';
export const punchListEmail = 'punchlist@lightedison.com';
export const flightRequestsEmail = 'flightrequests@lightedison.com';

//GAPI URLs & GUIDs - Sheets, Drive, Docs, Calendar, Scripts, Admin:
//Sheets
export const exportedReportsFolder = '1_BivYjSy_gBt_aRvUolcuW46j17gM60g';
export const exportedPositionAgreementsFolder = '1S3zs_-0jKmoz-Vwn_6YtNFA_W8jOo9pp';
export const dailyReportSheet = '1Kfkn9D72-zK438_idN9engpq9BLSvQ3BFFG3ilm7x04';
//Docs
export const PORequestForm = 'https://docs.google.com/forms/d/e/1FAIpQLSeHdIG_TfJ6zx6CsnVSoGklRTUt7zPf5ZuskAdhHsSr_4u1RQ/viewform';
export const AuxiliaryEquipRequestForm =
  'https://docs.google.com/forms/d/e/1FAIpQLScyy6GvIHx3n3Hpahn1P67aAUdJfvclpd4N_Gd6zHrjx-ER4g/viewform';
export const LodgingRequestForm = 'https://docs.google.com/forms/d/e/1FAIpQLSdLgQ5JGDiNqjxSmWvmld1mB2At40JHaaPIDVAwDm2aB85JBg/viewform';
export const DeliveryReceiptForm = 'https://docs.google.com/forms/d/e/1FAIpQLSeCMQBB3Tk9zmF65KtejO17HtNWOrK2pYxo_Fm7Uy6JDYGtAQ/viewform';
export const ProjectShareFolderRequestForm =
  'https://docs.google.com/forms/d/e/1FAIpQLSebfeB2sdWGV-24VIFkPazE--ez6KOF1RB7bXi7P4_kDMT8mQ/viewform';
export const BenefitPlanCalculator = 'https://docs.google.com/spreadsheets/d/1Wum6O5AU6iuPglPSjRwEQqUAmKOhtgyw2R2y_kZNsrE/copy';
export const BenefitPlanCalculator2024 = 'https://docs.google.com/spreadsheets/d/1dZum0VeD_gFqr3tGimwj6t2zl2MO6cYdVofct0JO2CY/copy';
export const positionAgreementDocument = '1PAAQFhZTAM_1IXyPEmvjKYbzg26bideANNaqFJ9glDM';
export const contractedDocument = '1dKve3pHItJCdcuEzXZnyrO5NNtdbFyj-S9GWjv4QrwA';
//Drive
export const fieldOperationsFolder = 'https://drive.google.com/drive/folders/10rDOqtFZfV9DgsAYoiY18vARuQdSguPD';
export const FederalAndStateLaborLawPosters = 'https://drive.google.com/drive/u/0/folders/15OgPtAYhVtpIuTBqY-M1k82BoLTylgAV';
//Scripts
export const imageDownloadScript =
  'https://script.google.com/a/lightedison.com/macros/s/AKfycbyP_25rlTMQd3FVA6XDxirjor2gn97z3P9Q1LbmOuMowxT8WQXv/exec?opptyID=';
export const proxyQBScript = '/macros/s/AKfycbwRcrQnJD4L8V4Mcpgm_3f3jF0kEY0v8RFrm2ZwAJ2W-CU_sBBZ/exec';
//Calendar
export const shipmentsCalendar = 'c_8u2k6jvedod1bn9bmk75mvvt4k@group.calendar.google.com';
export const leaveRequestsCalendar = 'lightedison.com_r6g589vm4ta2ca9imr76dudjd8@group.calendar.google.com';

//Policy Document URLs:
export const OpenEnrollmentForm =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2FOpen%20Enrollment%20LightEdison.pdf?alt=media&token=23cb3ce7-64d5-48bf-9ddc-54098171224f';
export const OpenEnrollmentForm2024 =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FOpen%20Enrollment%20Overview%202024%20(2).pdf?alt=media&token=723ac31b-22b3-47bf-a13c-6df1a0c45610';
export const HealthcareBenefits =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2FLightEdison%20Plan%20Outline.pdf?alt=media&token=38253ca4-d2e8-4ed4-837d-d53588d296b1';
export const HealthcareEnrollment =
  'https://na2.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDscJMjHLFr1x3IeVRlNuNaPsskBnm80H-BRtsYPm5rBEZJ6cXkFMhpMWLi9gm2Cxs*';
export const HealthcareBenefits2024 =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FLE%20Plan%20Outlines.pdf?alt=media&token=cbf07ed5-caa3-4869-9db6-0be8d0245df9';
export const HealthcareEnrollment2024 =
  'https://secure.rightsignature.com/templates/afd397b1-bea7-44de-9cf6-1c09df2eff54/template-signer-link/ab580c3330bc82176ade31ac17a071a8';
export const DentalVisionLifeBenefits =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2FWelcome%20to%20Guardian.pdf?alt=media&token=b7cb0ed7-d1ed-416c-9f5b-b0a9e1007cd2';
export const DentalVisionLifeEnrollment =
  'https://secure.rightsignature.com/templates/5489b954-2ccd-42cc-b745-4c87a8448117/template-signer-link/6432a0a7d7608fcf2db5331a986afa6f';
export const DentalVisionLifeBenefits2024 =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FbenefitSummary.pdf?alt=media&token=4f91e42b-2a1e-4d21-aa0d-d9a000ca0045';
export const DentalVisionLifeEnrollment2024 =
  'https://secure.rightsignature.com/templates/f3baad9a-73f8-4cc0-9f66-aa914cb31200/template-signer-link/d956db16980040d67dd5219100cf09ca';
export const HSABenefits =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2Fhsa-winning.pdf?alt=media&token=13d23a89-3462-42c2-af98-c1d53ad479c4';
export const HSAEnrollment =
  'https://na2.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhD0m56pK_pLfsT8fKfoGqm1P-3Z2lQf9N5biOcPvLG9IIACH8mTFw0GMTd4FP17Ods*';
export const HSABenefits2024 =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2Fhsa-winning.pdf?alt=media&token=13d23a89-3462-42c2-af98-c1d53ad479c4';
export const HSAEnrollment2024 =
  'https://secure.rightsignature.com/templates/dd203f78-0d2a-4730-b1ad-8748cc6a8aee/template-signer-link/6ab4fad7bf3952fa6250f71d08a1e449';
export const RetirementBenefits =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F1%20LE%2C%20LightEdison%20401(k)%20Brochure%20and%20Enrollment%2012-20-22.pdf?alt=media&token=720a1fab-4d68-4ce5-8944-97c7ca56935d';
export const RetirementEnrollment = 'https://myretirementbenefit.voya.com/17ao';
export const RetirementBenefits2024 =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/LightEdison%2FOperations%2FTalent%2FHealthcare%20Benefits%2F2024%2FVoya%20Enrollment%20Packet.pdf?alt=media&token=ad0d21b2-89f6-484c-a4d4-14adea49f960';
export const RetirementEnrollment2024 = 'https://myretirementbenefit.voya.com/17ao';
export const ColoradoDOL =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/file_uploads%2Fdepartment_of_labor_notifications%2Fdepartment_of_labor_notifications.pdf?alt=media&token=f933f73d-10df-4141-a48a-0f4e6f4d89a0';
export const OSHAForm =
  'https://firebasestorage.googleapis.com/v0/b/light-edison.appspot.com/o/file_uploads%2Fosha_300_form%2FLE%202023%20300%20A.pdf?alt=media&token=b49c1d68-cf82-496d-98e9-ee723a44b23f';
