<template>
  <v-app-bar class="lightedison__header" app elevation="1" color="terciary" dark>
    <v-dialog v-model="routeSOPDialog">
      <v-card>
        <v-container>
          <RouteSOPs v-for="match in matched" :key="match.path" :match="match"> </RouteSOPs>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sopDialog"
      @input="
        sopDialog = false;
        sopID = null;
      "
    >
      <v-card>
        <v-container>
          <SOPForm v-if="sopDialog" :sopID="sopID" :embedded="true"></SOPForm>
        </v-container>
      </v-card>
    </v-dialog>
    <router-link to="/home">
      <v-img class="logo-size" :src="$vuetify.breakpoint.smAndUp ? lightedison_logo : lightedison_sm_logo" />
    </router-link>
    <v-app-bar-nav-icon class="toggle-nav" @click.stop="navButtonToggle()"></v-app-bar-nav-icon>
    <v-toolbar light dense height="38px" rounded elevation="1">
      <v-menu offset-y v-model="menu" :close-on-click="true" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-container>
            <v-text-field
              @click="menu = true"
              v-model="search"
              hide-details
              prepend-icon="mdi-magnify"
              placeholder="Search..."
              single-line
              dense
              flat
              solo
            >
            </v-text-field>
          </v-container>
        </template>

        <v-list v-if="searchResult.routeItems.length > 0 || searchResult.opptyItems.length > 0">
          <v-list-item v-for="route of searchResult.routeItems" :key="route.path" link @mousedown="goToRoute(route.path)">
            <v-list-item-content>
              <v-list-item-title> #{{ route.name }} </v-list-item-title>
              <v-list-item-subtitle>
                {{ route.path }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-for="oppty of searchResult.opptyItems" :key="oppty.opportunityRecordID" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Opportunity: {{ oppty.opportunityName }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="route in oppty.routes"
              :key="route.name + oppty.opportunityRecordID"
              :to="{
                name: route.name,
                params: {
                  [route.param]: oppty.opportunityRecordID,
                },
              }"
              @click="menu = false"
              link
            >
              <v-list-item-title>{{ route.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

    <v-col cols="1">
      <v-menu v-if="sops.length" open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="yellow">mdi-information-outline</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link v-for="sop of sops" :key="sop.id" @click="handleSOP(sop.sop)">
            <v-list-item-content>
              <v-list-item-title>
                {{ sop.sopName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col cols="1" class="avatar-column">
      <v-menu open-on-click offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="45px" v-bind="attrs" v-on="on">
            <v-img :src="user.photoURL || lightEdisonLogo3"></v-img>
          </v-avatar>
        </template>

        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.displayName }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item link @click.stop="$emit('profile', user)">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>

          <v-list-item link @click.stop="$emit('password')">
            <v-list-item-icon>
              <v-icon>mdi-form-textbox-password</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>

          <!-- TODO: DEV-873: Re-enable theme selection -->
          <v-list-item :dark="$vuetify.theme.dark" link @click.stop="toggleTheme">
            <v-list-item-icon
              ><v-icon>mdi-{{ $vuetify.theme.dark ? 'weather-sunny' : 'weather-night' }}</v-icon></v-list-item-icon
            >
            <v-list-item-title>{{ $vuetify.theme.dark ? 'Light' : 'Dark' }} Mode</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="handleRouteSOP" v-if="canAddSOPs">
            <v-list-item-icon><v-icon>mdi-file-document-outline</v-icon></v-list-item-icon>
            <v-list-item-title>Add SOP to Route</v-list-item-title>
          </v-list-item>

          <v-list-item link @click.stop="linkWithGoogle" v-if="showLinkWithGoogle">
            <v-list-item-title>Link with Google</v-list-item-title>
          </v-list-item>

          <v-list-item @click="clearCache">
            <v-list-item-icon><v-icon>mdi-broom</v-icon></v-list-item-icon>
            <v-list-item-title>Clear Cache</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link @click.stop="$emit('logout')">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-app-bar>
</template>

<script lang="ts">
import lightedison_logo from '@/assets/lightedison.png';
import lightedison_sm_logo from '@/assets/lightedison_sm.png';
import RouteSOPs from '@/components/route-sops/RouteSOPs.vue';
import { useService } from '@/composables/useServices';
import { companyEmailDomain, LightEdisonLogo3 } from '@/config/constants';
import RouteSOPController from '@/core/interfaces/route-sops/RouteSOPController';
import SOPForm from '@/pages/customer_relationships_management/sops/sop_form/SOPForm.vue';
import { checkPermissions } from '@/services/access-control/AccessControlService';
import { CacheService } from '@/services/data/cache/cacheService';
import { SnapshotRepository } from '@/services/data/firebase/db/snapshotRepository';
import EventBus from '@/utils/event-bus';
import firebase from 'firebase';
import { defineComponent } from 'vue';
import { Route } from 'vue-router';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  components: {
    RouteSOPs,
    SOPForm,
  },
  setup() {
    return {
      snapshotRepository: useService(SnapshotRepository),
      cacheService: useService(CacheService),
    };
  },
  data() {
    return {
      user: {
        photoURL: null,
        email: null,
        displayName: null,
        phoneNumber: null,
      },
      sops: [],
      canAddSOPs: false,
      search: null,
      model: null,
      isLoading: false,
      menu: false,
      showLinkWithGoogle: false,
      routeSOPDialog: false,
      sopDialog: false,
      sopID: null,
      matched: [],
      lightedison_logo: lightedison_logo,
      lightedison_sm_logo: lightedison_sm_logo,
      lightEdisonLogo3: '',
    };
  },
  mounted() {
    this.lightEdisonLogo3 = LightEdisonLogo3;
    this.initializeTheme();

    const firebaseAuth = firebase.auth();
    const currentUser = firebaseAuth.currentUser;
    if (currentUser?.email?.includes(companyEmailDomain)) {
      firebaseAuth.fetchSignInMethodsForEmail(currentUser.email).then((providers) => {
        this.showLinkWithGoogle = !providers.includes('google.com');
      });
    }
    const { photoURL, email, displayName, phoneNumber } = currentUser || {};

    this.user.photoURL = photoURL;
    this.user.email = email;
    this.user.displayName = displayName;
    this.user.phoneNumber = phoneNumber;

    checkPermissions({
      routeSOPs: ['create', 'read', 'update', 'delete'],
    }).then((canAddSOPs) => {
      this.canAddSOPs = !!canAddSOPs;
    });

    EventBus.$on('OpenSOP', (event: { id: string }) => {
      if (event && event.id) {
        this.handleSOP(event.id);
      }
    });
  },
  computed: {
    routerItems() {
      return this.$router.options.routes.filter((route: Route) => !route.path.includes(':'));
    },
    opptyRouterItems() {
      return this.$router.options.routes.filter((route: Route) => route.meta && route.meta.opptySearchable);
    },
    ...mapGetters('utils', ['getDrawer']),
    ...mapGetters('opportunities', ['opportunities']),
    searchResult() {
      if (!this.search || !this.search.length) {
        return { routeItems: [], opptyItems: [] };
      }

      const items = this.routerItems
        .filter((item: Route) => {
          const itemName = item.name?.toLowerCase() || '';
          const searchValue = this.search.toLowerCase();

          return itemName.includes(searchValue) || item.path.includes(searchValue);
        })
        .slice(0, 10);

      const opptyItems =
        this.search.length < 4
          ? []
          : this.opportunities
              .filter(
                (oppty) =>
                  oppty.opportunityRecordID === this.search || oppty.opportunityName.toLowerCase().includes(this.search.toLowerCase()),
              )
              .map(({ opportunityRecordID, opportunityName }) => ({
                opportunityRecordID,
                opportunityName,
                routes: this.opptyRouterItems.map(({ name, path }) => ({
                  name,
                  param: path.match(/:([^\/]+)/)[1],
                })),
              }))
              .slice(0, 10);

      return { routeItems: items, opptyItems };
    },
  },
  watch: {
    $route(to) {
      if (!to.matched[0] || to.matched.length === 0) {
        return;
      }

      const [match] = to.matched;
      RouteSOPController.getRouteSOPs({
        filters: [{ field: 'route', operator: '==', value: match.path }],
      })
        .then((routeSOPs) => {
          this.sops = routeSOPs;
        })
        .catch((error) => {
          const errorObject = {
            type: 'error',
            message: 'Error in fetching SOPs by route.',
          };

          this.addNotification(errorObject);
          console.log(error);
        });
    },
  },
  methods: {
    ...mapMutations('utils', ['navButtonToggle']),
    ...mapActions('notifications', ['addNotification']),
    goToRoute(path: string) {
      this.$router.push({ path });
    },
    handleSOP(sopID: string) {
      this.sopID = sopID;
      this.sopDialog = true;
    },
    handleRouteSOP() {
      this.matched = (this.$route.matched || []).map(({ name, path }: Route) => ({
        name,
        path,
      }));
      this.routeSOPDialog = true;
    },
    linkWithGoogle: function () {
      firebase
        .auth()
        .currentUser?.linkWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((_result) => {
          swal('Success', 'Accounts successfully linked.', 'success');
        })
        .catch((error) => {
          swal('Error', error.message, 'error');
        });
    },
    clearCache() {
      this.cacheService.clear();
      this.snapshotRepository.clear();
      localStorage.clear();
    },
    initializeTheme() {
      this.$vuetify.theme.dark = false;
      // TODO: DEV-873: Re-enable dark mode defaulting
      // const storedTheme = localStorage.getItem('theme');
      // if (storedTheme) {
      //   this.$vuetify.theme.dark = storedTheme === 'dark';
      // } else {
      //   const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      //   this.$vuetify.theme.dark = prefersDark;
      // }
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('theme', this.$vuetify.theme.dark ? 'dark' : 'light');
    },
  },
});
</script>
<style lang="scss" scoped>
@import 'vuetify/src/styles/settings/_variables.scss';
.lightedison__header {
  left: 0 !important;
  height: 64px !important;
  .v-toolbar__content {
    .logo-size {
      width: 200px;
      height: auto;
      max-height: 56px;
    }
    .toggle-nav {
      margin-left: 45px !important;
    }
    .avatar-column {
      max-width: fit-content;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    :deep(.v-toolbar__content) {
      padding: 4px;
      .logo-size {
        width: 50px;
        height: auto;
        max-height: 50px;
      }
      .toggle-nav {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
