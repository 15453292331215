import { SuperAdminRole } from '@/config/constants';
import { ServiceContainer } from '../serviceContainer';
import { UserService } from './userService';
import { UserEmails, UserIds } from '@/config/constants';

export class PermissionsService {
  private readonly userService: UserService;

  private role: string = '';

  constructor(userService: UserService = ServiceContainer.get(UserService)) {
    this.userService = userService;
  }

  public async initialize(): Promise<void> {
    this.role = await this.userService.getRoleId();
  }

  public canPushAP(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.HL]);
  }

  public isAccounting(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.HL]);
  }

  public canViewAllTransactions(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.HL, UserEmails.BVD, UserEmails.CJS]);
  }

  public canEditOrgChart(): boolean {
    return this.isSuperAdmin();
  }

  public canAccessReparentSpaces(): boolean {
    return this.isSuperAdmin();
  }

  public canAccessLaborCosting(): boolean {
    return this.isSuperAdmin();
  }

  public isManager(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.HL]);
  }

  public canViewFundraisingReports(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.SMS]);
  }

  public canManageChecklistTemplates(): boolean {
    return this.isSuperAdmin();
  }

  public canManageContructionEvaluationTemplates(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.SMS]);
  }

  public canManageImprovementRequests(): boolean {
    return this.isSuperAdmin();
  }

  public canAccessOpportunityTimeDashboard(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.SMS]);
  }

  public canAccessMultipleContacts(): boolean {
    return this.isSuperAdmin() || this.emailAllowed([UserEmails.HL, UserEmails.ML]);
  }

  public canExportTimesheets(): boolean {
    return (
      this.isSuperAdmin() ||
      this.emailAllowed([
        UserEmails.SMS,
        UserEmails.HL,
        UserEmails.AB,
        UserEmails.EJ,
        UserEmails.BAC,
        UserEmails.CA,
        UserEmails.KL,
        UserEmails.CJS,
      ])
    );
  }

  public isFieldSuperintendent(userId: string): boolean {
    const fieldSuperIntendentsIDs: Array<string> = [UserIds.JMJ, UserIds.CA, UserIds.RC, UserIds.DW, UserIds.SR, UserIds.TMJ, UserIds.SMS];
    return fieldSuperIntendentsIDs.includes(userId);
  }

  private emailAllowed(allowedList: string[]): boolean {
    const email = this.userService.getCurrentUser().email;
    return email ? allowedList.includes(email) : false;
  }

  private isSuperAdmin(): boolean {
    if (!this.role) throw new Error('PermissionsService is not initialized');
    return this.role === SuperAdminRole;
  }
}
