import {
  OpenEnrollmentForm,
  HealthcareBenefits,
  HealthcareEnrollment,
  DentalVisionLifeBenefits,
  DentalVisionLifeEnrollment,
  HSABenefits,
  HSAEnrollment,
  RetirementBenefits,
  RetirementEnrollment,
  BenefitPlanCalculator,
  OpenEnrollmentForm2024,
  HealthcareBenefits2024,
  HealthcareEnrollment2024,
  DentalVisionLifeBenefits2024,
  DentalVisionLifeEnrollment2024,
  HSABenefits2024,
  HSAEnrollment2024,
  RetirementBenefits2024,
  RetirementEnrollment2024,
  BenefitPlanCalculator2024,
} from '@/config/constants';

const items2023 = {
  Overview: {
    title: 'Overview',
    subtitle: false,
    url: OpenEnrollmentForm,
  },
  Medical: {
    title: 'Medical',
    subtitle: false,
    url: HealthcareBenefits,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: HealthcareEnrollment,
    },
  },
  DentalVisionLife: {
    title: 'Dental, Vision, Life',
    subtitle: false,
    url: DentalVisionLifeBenefits,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: DentalVisionLifeEnrollment,
    },
  },
  HSA: {
    title: 'HSA',
    subtitle: false,
    url: HSABenefits,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: HSAEnrollment,
    },
  },
  '401k': {
    title: '401k',
    subtitle: false,
    url: RetirementBenefits,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: RetirementEnrollment,
    },
  },
  RateCalculator: {
    title: 'Rate Calculator',
    subtitle: false,
    url: false,
    enrollBtn: {
      title: 'Open in Google Sheets?',
      url: BenefitPlanCalculator,
    },
  },
};

const items2024 = {
  Overview: {
    title: 'Overview',
    subtitle: false,
    url: OpenEnrollmentForm2024,
  },
  Medical: {
    title: 'Medical',
    subtitle: false,
    url: HealthcareBenefits2024,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: HealthcareEnrollment2024,
    },
  },
  DentalVisionLife: {
    title: 'Dental, Vision, Life',
    subtitle: false,
    url: DentalVisionLifeBenefits2024,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: DentalVisionLifeEnrollment2024,
    },
  },
  HSA: {
    title: 'HSA',
    subtitle: false,
    url: HSABenefits2024,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: HSAEnrollment2024,
    },
  },
  '401k': {
    title: '401k',
    subtitle: false,
    url: RetirementBenefits2024,
    enrollBtn: {
      title: 'Ready to Enroll?',
      url: RetirementEnrollment2024,
    },
  },
  RateCalculator: {
    title: 'Rate Calculator',
    subtitle: false,
    url: false,
    enrollBtn: {
      title: 'Open in Google Sheets?',
      url: BenefitPlanCalculator2024,
    },
  },
};

export const items = {
  2023: items2023,
  2024: items2024,
};
